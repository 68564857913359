.home-hero {
	padding: 0;
	background: $black;

	&-image-wrapper {
		height: 400px;
		position: absolute;
		width: 100%;
		max-width: 100%;
		top: 0;
		overflow: hidden;

		@include break(medium) {
			height: 500px;
		}

		@include break(large) {
			height: 800px;
		}
	}

	.dots {
		position: absolute;
		height: 200px;
		width: 200px;
		top: calc(50% - 100px);
		left: calc(50% - 100px);
		z-index: 1;
		transition: linear 1s;

		&.tracking {
			transition: linear 8s;
			// -webkit-animation-name: spin;
			// -webkit-animation-duration: 4000ms;
			// -webkit-animation-iteration-count: infinite;
			// -webkit-animation-timing-function: linear;
			// -moz-animation-name: spin;
			// -moz-animation-duration: 4000ms;
			// -moz-animation-iteration-count: infinite;
			// -moz-animation-timing-function: linear;
			// -ms-animation-name: spin;
			// -ms-animation-duration: 4000ms;
			// -ms-animation-iteration-count: infinite;
			// -ms-animation-timing-function: linear;

			// animation-name: spin;
			// animation-duration: 4000ms;
			// animation-iteration-count: infinite;
			// animation-timing-function: linear;

			// -webkit-animation-fill-mode: forwards;
			// -moz-animation-fill-mode: forwards;
			// -ms-animation-fill-mode: forwards;
			// -o-animation-fill-mode: forwards;
			// animation-fill-mode: forwards;
		}
	}

	.hero-image {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		max-width: 100%;
	}

	.container {
		background: $white;

		@include break(medium) {
			background: transparent;
		}
	}

	&-text {
		margin-top: 400px;

		@include break(medium) {
			margin-top: 500px;
		}

		@include break(large) {
			margin-top: 800px;
		}

		.container,
		.home-hero-summary-image {
			@extend %flex;
		}
	}

	&-robot,
	&-human {
		@include flexbox(100%);

		&-inner {
			width: 100%;

			@include break(medium) {
				width: 70%;
			}

			@include break(large) {
				width: 50%;
			}
		}

		h1,p {
			@include font-size(40);

			@include break(tablet) {
				@include font-size(50);
			}

			@include break(medium) {
				@include font-size(70);
			}
		}
	}

	&-robot {
		position: relative;
		margin-top: -82px;

		@include break(medium) {
			margin-top: -102px;
		}

		&:before {
			content: '';
			height: 100%;
			width: 3000px;
			left: -3000px;
			top: 0;
			background: $white;
			position: absolute;
		}

		&-inner {
			background: white;
			padding: $gap;
			margin-left: 0;
		}

		p {
			font-weight: $normal;
			line-height: 1;

			@include break(medium) {
				text-align: right;
			}
		}
	}

	&-human {
		background: $white;
		position: relative;

		&:before {
			content: '';
			height: 100%;
			width: 3000px;
			right: -3000px;
			top: 0;
			background: $black;
			position: absolute;

			@include break(medium) {
				left: -3000px;
				right: auto;
				background: $white;
			}
		}

		&-inner {
			background: $black;
			color: $white;
			padding: $gap;
			margin-right: 0;
		}

		p {
			font-family: $font-header-human;
			font-weight: $bold;
			line-height: 1;
			text-align: right;

			@include break(medium) {
				text-align: left;
			}
		}
	}

	&-summary,
	&-image {
		@include flexbox(100%);

		@include break(medium) {
			@include flexbox(50%);
		}
	}

	&-image {
		height: 500px;
		margin-left: -$gap * 2;
		margin-right: -$gap * 2;
		max-width: calc(100% + 4rem);
		flex: 0 calc(100% + 4rem);
		position: relative;

		&:after {
			content: '';
			height: 30vw;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background-image: url('/assets/images/grid-dots-white.svg');
			background-size: contain;
			background-position: top center;
		}

		@include break(medium) {
			min-height: 760px;
			margin-left: auto;
			margin-right: calc((100vw - 800px) / 2 * -1);
			flex: 0 calc(50% + ((100vw - 800px) / 2));
			max-width: calc(50% + ((100vw - 800px) / 2));
		}

		@include break(xlarge) {
			margin-right: calc((100vw - 1280px) / 2 * -1);
			flex: 0 calc(50% + ((100vw - 1280px) / 2));
			max-width: calc(50% + ((100vw - 1280px) / 2));
		}

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	&-summary {
		background: $white;
		position: relative;

		@include break(medium) {
			margin-bottom: $gap * 5;
		}

		@include break(large) {
			margin-bottom: $gap * 15;
		}

		&-inner {
			padding: $gap * 2 0;

			@include break(medium) {
				padding: $gap * 2;
			}
		}

		p {
			@include font-size(18);
			line-height: 150%;

			@include break(tablet) {
				@include font-size(26);
			}

			.hv {
				font-weight: $bold;
			}

			&+.button {
				margin-top: $gap * 2;
			}
		}

		&:before {
			content: '';
			height: 100%;
			width: 3000px;
			left: -3000px;
			top: 0;
			background: $white;
			position: absolute;
		}
	}
}

// spin animation
@-ms-keyframes spin {
	from {
		-ms-transform: rotate(0deg);
	}

	to {
		-ms-transform: rotate(360deg);
	}
}

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}

	to {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
