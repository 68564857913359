/* +++++++++++++++++++
+++ LAYOUT: SEARCH +++
+++++++++++++++++++ */

.search {
	display: inline-block;
	margin: 0;
	position: relative;
	width: 160px;
	
	&-input {
		font-size: 0.9em;
		height: 1.8em;
		margin-top: 0;
		padding: 0 $gap * 2.5 0 $gap / 2;
	}
	
	&-icon {
	    @include centerer(false, true);
		fill: $white;
		cursor: pointer;
		height: 15px;
		display: inline-block;
		right: $gap / 3.5;
		pointer-events: none;
		width: 15px;
		
		&:hover {
			.search-button {
				background-color: $secondary;
			}	
		}
	}
	&-button {
		border-radius: 0 $corner $corner 0;
		border-left: $line solid $grey80;
		height: 100%;
		overflow: hidden;
		padding: 0;
		position: absolute;
		right: 0;
		text-indent: -999rem;
		top: 0;
		width: 28px;
	}	
}