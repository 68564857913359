/* ++++++++++++++++++++++
+++ LAYOUT: PULLQUOTE +++
++++++++++++++++++++++ */

.pullquote {
	@extend %flex;
	font-family: $font-quote;
	justify-content: center;
	margin: $gap * 2 auto auto auto;
	padding: 0 0 0 $gap * 2;
	text-align: left;
	border-left: solid 5px $green;

	.page-builder & {
		border-color: $pink;
	}

	@include break(small) {
		align-items: center;
		text-align: left;
	}

	@include break(medium) {
		// padding: $gap * 2 $gap * 3;
	}

	@include break(xlarge) {
		// margin: 0 auto;
		// padding: $gap * 2 0;
		width: $page-width;
	}

	&-image {
		@include flexbox(50%);

		@include break(small) {
			@include flexbox(20%);
		}

		img {
			// border-radius: 100%;
		}

		+.pullquote-info {
			@include flexbox(100%);
			padding: $gap * 1.5 0 0 0;

			@include break(small) {
				@include flexbox(80%);
				padding: 0 0 0 $gap * 2;
			}

			@include break(medium) {
				padding: 0 $gap * 2;
			}

			blockquote {
				font-size: 1.3em;

				@include break(medium) {
					font-size: 1.8em;
				}
			}
		}
	}

	&-info {
		@include flexbox(100%);
	}

	blockquote {
		font-weight: $bold;
		padding-bottom: $gap / 1.5;
		// quotes: "“""”""‘""’";
		font-family: $font-header-human;
		line-height: 130%;
		letter-spacing: 0.01em;
		@include font-size(30);

		@include break(medium) {
			@include font-size(40);
		}

		// &:before {
		// 	content: open-quote;
		// 	display: inline-block;
		// }

		// &:after {
		// 	content: close-quote;
		// 	display: inline-block;
		// }
	}

	cite {
		@include font-size(14);
		line-height: 130%;
		letter-spacing: 0.2em;
		text-transform: uppercase;
		margin-top: $gap * 2.5;
		margin-left: $gap * 2.5;
		position: relative;
		padding: 0 !important;
		display: block;

		&:before {
			content: '';
			position: absolute;
			left: -$gap * 2.5;
			bottom: $gap / 2;
			height: $gap * 2;
			width: $gap * 2;
			border-left: 2px solid $black;
			border-bottom: 2px solid $black;

			.page-builder & {
				border-color: $white;
			}
		}


		@include break(medium) {
			font-size: 1.4em;
		}
	}

	&-share {
		display: block;
		position: relative;

		@include break(medium) {
			display: inline-block;
			padding-left: $gap * 5;
			top: -4px;
		}

		&-title {
			@extend %font-content;
			color: $grey30;
			font-size: 0.7em;
			font-weight: $normal;
			padding: 0 0 0 $gap * 4;
			position: relative;
			text-transform: uppercase;

			&:before {
				background-color: $grey30;
				content: '';
				display: block;
				height: 1px;
				left: 0;
				position: absolute;
				top: 6px;
				width: 36px;
			}
		}

		&-element {
			@extend %transition;
			background-color: $twitter;
			// border-radius: 100%;
			color: $white;
			display: inline-block;
			height: 30px;
			margin: 0 0 0 $gap / 2;
			text-align: center;
			width: 30px;

			.icon {
				margin-top: 3px;
			}
		}
	}
}
