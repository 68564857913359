/* +++++++++++++++++++
+++ LAYOUT: FOOTER +++
+++++++++++++++++++ */

// Layout of the site footer

.footer {
	padding: 0;
	margin-top: 0;
	z-index: map-get($zindex, footer);

	&-top {
		background: linear-gradient(180deg, $blue 0%, #986FAA 75.54%, $pink 95.75%);
		padding: $gap * 4 0 $gap 0;

		@include break(medium) {
			padding: $gap * 8 0 $gap 0;

			.home & {
				padding-top: $gap * 2;
			}
		}

		.home & {
			padding-top: $gap * 2;
		}
	}

	&-header {
		@extend %flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 $gap * 2 0;

		@include break(medium) {
			margin: $gap * 2 0 $gap * 4 0;
		}

		* {
			margin-top: 0;
		}

		&-buttons {
			width: 100%;
			margin: $gap * 2 0;

			@include break(tablet) {
				width: auto;
				margin: 0;
			}
		}


		.search {
			@include flexbox(100%);

			@include break(tablet) {
				@include flexbox(30%);
			}

			&-input {
				background: rgba($white, 0.1);
				border-color: transparent;
				color: $white;
				font-size: 1em;
				height: auto;
				padding: $gap / 1.5 $gap * 2.5 $gap / 1.5 $gap;
			}

			&-icon {
				height: 24px;
				right: $gap / 1.8;
				width: 24px;
			}

			&-button {
				border-left: $line solid rgba($white, 0.1);
				width: 45px;
			}
		}
	}

	&-logo {
		display: block;
		margin: $gap 0;
		width: 100%;
		height: 100px;
		width: 100px;

		@include break(medium) {
			height: 150px;
			width: 150px;
		}

		@include break(small) {
			display: inline-block;
			margin: 0;
		}

		img {
			width: auto;
		}

	}

	&-nav {
		// &:first-child {
		//     border-top: 0;
		//     margin-left: 0;
		//     padding-top: 0;
		// }
		// &:nth-child(3) {
		//     @include break(small) {
		//     	margin-left: 0;
		//     }
		//     @include break(medium) {
		//     	margin-left: 2%;
		//     }
		// }

		&:not(.quicklinks):not(.tools):not(.social) {
			flex: 100%;
			max-width: 100%;
			margin: $gap * 2 0 0 0;

			@include break(medium) {
				column-count: 2;
			}

			@include break(large) {
				column-count: 2;
				flex: 45%;
				max-width: 45%;
				margin: 0;
			}
		}

		&.quicklinks {
			flex: 50%;
			max-width: 50%;
			margin: 0;

			@include break(large) {
				flex: 35%;
				max-width: 35%;
			}
		}

		&.tools {
			flex: 50%;
			max-width: 50%;
			margin: $gap * 2 0 0 0;

			@include break(large) {
				flex: 20%;
				max-width: 20%;
				margin: 0;
			}
		}

		&-outer {
			@extend %flex;

			* {
				margin-top: 0;
			}
		}

		&.social {
			@include flexbox(100%);
			margin: 0;

			@include break(small) {
				@include flexbox(auto);
				text-align: left;
			}
		}

		&-item {
			margin-top: 0;

			.footer-nav-item-title {
				font-size: 1em;
				padding-bottom: $gap;
			}

			.nav-item-link {
				font-size: 1em;
				margin: 0;
				padding: 0 0 $gap / 2 0;
				display: inline-block;

				&:hover {
					text-decoration: underline;
					color: $black;
				}
			}

			&.social {
				display: inline-block;
				margin-right: $gap / 1.5;
				text-align: center;

				&:last-child {
					margin-right: 0;
				}

				.nav-item-link {
					@extend %transition;
					background-color: $white;
					border-radius: 100%;
					display: inline-block;
					height: 50px;
					padding: 0;
					position: relative;
					width: 50px;

					&:hover {
						background-color: $yellow;

						.icon {
							color: $black;
						}
					}

					&.instagram,
					&.youtube {
						.icon {
							padding: 2px;
						}
					}

					&.instagram_new {
						.icon {
							padding: 4px;
						}
					}

					.icon {
						@include centerer(true, true);
						color: $primary;
						margin: 0;
						height: $gap * 2;
						width: $gap * 2;
					}
				}
			}
		}
	}

	p {
		font-size: 0.9em;
	}

	&-details,
	&-nav {
		@include flexbox(100%);
		padding-top: $gap * 1.5;
		margin-bottom: $gap;

		@include break(small) {
			@include flexbox(48%);
			border-top: 0;
			margin-left: 4%;
			padding-top: 0;
		}

		@include break(medium) {
			@include flexbox(16.22%);
			margin-left: 2%;
		}
	}

	&-details {
		margin-right: 0;

		.footer-nav-item-title {
			font-size: 1em;
			padding-bottom: $gap;
		}

		p {
			padding-bottom: $gap;

			a {
				&:hover {
					color: $primary;
				}
			}
		}
	}

	&-credits {
		margin-top: $gap * 8;

		@include break(large) {
			margin-top: $gap * 12;
		}

		* {
			margin-top: 0;
		}

		.container {
			@extend %flex;
		}

		p {
			@include flexbox(100%);

			@include break(large) {
				@include flexbox(50%);
			}

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin: $gap 0 0 0;
				text-align: left;

				@include break(large) {
					text-align: right;
					margin: 0;
				}
			}
		}
	}

	&-bottom {
		background: linear-gradient(180deg, $pink 0%, #986FAA 31.28%, $blue 85.94%);
		height: $gap * 4;
	}

}
