/* ++++++++++++++++++
+++ LAYOUT: CARDS +++
++++++++++++++++++ */

// Cards are more visually interesting and content-light than a standard listing page.

.cards {
	@extend %flex;
	position: relative;
	width: 100%;

	.resources &,
	.facts & {
		width: calc(100% + 2em);
		margin-left: -$gap;
		margin-right: -$gap;
	}

	.latest & {
		width: calc(100% + 2em);
		margin-left: -$gap;
		margin-right: -$gap;

		@include break(medium) {
			width: calc(100% + 8em);
			margin-left: -$gap * 4;
			margin-right: -$gap * 4;
		}
	}

	.facts & {
		margin-top: $gap;

		@include break(medium) {
			margin-top: 0;
		}
	}

	>* {
		@extend %flex;
		@include flexbox(100%);
		margin: $gap * 1.5 0;

		&:first-child:nth-last-child(1),
		&:first-child:nth-last-child(1)~article {
			@include break(tablet) {
				// @include flexbox(48%);
				@include flexbox(50%);
				margin-left: auto;
				margin-right: auto;
			}
		}

		&:first-child:nth-last-child(2),
		&:first-child:nth-last-child(2)~article {
			@include break(tablet) {
				// @include flexbox(48%);
				@include flexbox(50%);
			}
		}
	}

	@include break(tablet) {
		>* {
			@include flexbox(50%);
		}
	}

	@include break(medium) {
		>* {
			@include flexbox(33.33%);
		}
	}

	&-outer {
		.section-header {
			&.container {
				@include break(medium) {
					padding: 0 $gap * 2;
				}
			}
		}

		.container {
			@include break(large) {
				padding: 0;
			}
		}
	}
}

article {
	.first-blue & {
		&:first-of-type {
			.card {
				background-color: $blue;
			}
		}
	}
}

.card {
	@extend %card;
	@extend %flex;
	@extend %transition;
	overflow: hidden;
	width: 100%;

	&.resource-card,
	&.fact-card,
	&.latest-card {
		border: none;
		box-shadow: none;
		border-radius: 0;
		background: none;
		margin: 0 $gap;
	}

	&.latest-card {
		margin: 0 !important;
	}

	@include break(tablet) {
		margin: 0 $gap * 1.5;
	}

	&.hovered {
		// box-shadow: 0 2vw 4vw rgba(0, 0, 0, 0.2);
		transform: scale(1.02);

		.card-header-title {
			// color: $secondary;
			// text-decoration: underline;
		}

		&.resource-card,
		&.latest-card {
			box-shadow: none;
			transform: none;
		}

		&.latest-card {
			.card-header-title {
				text-decoration: none;
			}
		}
	}

	&-image {
		border-radius: $corner $corner 0 0;
		display: block;
		margin: 0;
		position: relative;
		width: 100%;

		@include break(tablet) {
			height: 25vh;
		}

		img {
			@include object-fit(4/3, 100%);
		}

		.card-featured {
			background-color: $primary;
			color: $white;
			font-size: 0.9rem;
			font-weight: $bold;
			left: 0;
			padding: $gap / 2.5 $gap / 1.5;
			position: absolute;
			top: $gap * 1.5;
		}
	}

	&-wrap {
		align-self: stretch;
		height: 100%;
		margin: 0;

		&-inner {
			color: $black;
			padding: $gap * 2;

			.resource-card &,
			.fact-card & {
				padding: 0 $gap * 2 0 0;
			}

			.latest-card & {
				padding: $gap * 3 $gap * 1;
				height: 100%;

				@include break(medium) {
					padding: $gap * 5;
				}

				.home & {
					padding: $gap * 3 $gap * 1;

					@include break(medium) {
						padding: $gap * 4;
					}
				}

			}

			.fact-card & {
				color: white;
			}

			.hv,
			.rv {
				@include font-size(18);

				@include break(medium) {
					@include font-size(22);
				}
			}

			p.hv {
				margin-top: $gap * 2;
			}
		}
	}

	&-header {
		margin-top: 0;

		+.card-summary {
			margin-top: $gap / 2;
		}

		&-title {
			font-size: 1.6em;

			.resource-card & {
				@include font-size(26);
				line-height: 130%;

				&:hover {
					color: $secondary;
					text-decoration: underline;
				}
			}

			.latest-card & {
				@include font-size(27);

				@include break(medium) {
					@include font-size(32);
				}
			}

			.fact-card & {
				border-left: 3px solid $yellow;
				padding: 10px 0 10px 20px;

				h3 {
					display: inline-block;
					text-transform: uppercase;
					font-family: $font-body;
					font-weight: $bold;
					border-radius: 0;
					color: $yellow;
					@include font-size(30);
				}

				&+.card-header {
					margin-top: $gap * 2;
				}
			}
		}

		&-date {
			@extend %font-meta;
			display: block;
			margin-top: $gap / 2;
		}
	}

	&-summary {
		p {
			font-family: $font-body-human;
			line-height: 160%;
			letter-spacing: 0.01em;

			.latest-card & {
				@include font-size(18);
				margin-top: $gap * 2;
			}
		}

		.resource-card & {
			margin-top: $gap * 2;
		}
	}

	&-button {
		&-outer {
			margin-top: $gap;
		}
	}

	&-count {
		width: 50px;
		height: 50px;
		border: solid 2px;
		border-radius: 100%;
		align-items: center;
		margin: 0;
		font-weight: bold;
		display: none;

		.home & {
			display: flex;

			&+.card-header {
				margin-top: $gap;
			}
		}

		p {
			@include font-size(32);
		}

	}

	&-arrowcircle {
		display: none;
		height: 60px;
		width: 60px;
		background: $black;
		border-radius: 100%;
		margin: $gap * 4 0 0 0;
		position: relative;

		&:before {
			content: '';
			height: 30px;
			width: 32px;
			background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1L33 16L18 31' stroke='white' stroke-width='2'/%3E%3Cpath d='M0 16L33 16' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
			background-size: cover;
			position: absolute;
			left: 13px;
			top: calc(50% - 15px);
			transition: ease 0.3s;
		}

		.page-builder &,
		.single-post & {
			display: block;
		}
	}
}

// Report layout

.reports {
	.card {
		&-image {
			background-color: $grey90;
			overflow: hidden;

			img {
				@extend %transition;
				bottom: -1em;
				border-radius: $corner $corner 0 0;
				box-shadow: $shadow;
				margin: $gap * 3 auto 0;
				position: relative;
				width: 60%;

				&:hover {
					bottom: -0.5em;
				}
			}
		}

		&-wrap {
			box-shadow: 0px -8px 22px 0px rgba($black, 0.3);
			z-index: 2;
		}
	}
}

// Listing layout

.listing {
	.card {
		background-color: transparent;
		border-radius: 0;
		box-shadow: none;

		&-wrap {
			border-top: solid 5px $primary;

			&-inner {
				padding: $gap 0 0 0;
			}
		}
	}

	&.secondary {
		.card {
			&-wrap {
				border-top: solid 5px $white;

				&-inner {
					color: $white;
				}
			}
		}
	}
}


// Two column layout
.two-col {
	.cards {
		>* {
			@include break(tablet) {
				@include flexbox(50%);
			}

			.card {
				@include break(small) {
					margin: 0 $gap;
				}

				&-image {
					height: 35vh;
				}
			}
		}
	}
}

// Three column layout
.three-col {
	.cards {
		>* {
			@include break(medium) {
				@include flexbox(33.33%);
			}

			.card {
				margin: 0 $gap * 1.5;

				&-image {
					height: 25vh;
				}
			}

			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2)~article {
				@include flexbox(50%);

				.card {

					&-image {
						height: 35vh;
					}
				}
			}
		}
	}
}

// Four column layout
.four-col {
	.cards {
		>* {
			@include break(large) {
				@include flexbox(25%);
			}

			.card {
				margin: 0 $gap * 1;
			}

			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3)~article {
				@include flexbox(33.33%);
			}

			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2)~article {
				@include flexbox(50%);

				.card {
					margin: 0 $gap * 1;

					&-image {
						@include break(large) {
							height: 35vh;
						}
					}
				}
			}
		}
	}
}




.cards-listing {
	overflow: visible;
	padding: 0;
	position: static;
	direction: ltr;

	.container {
		max-width: 100%;
		padding: 0;
		z-index: 2;
	}

	.cards {
		article {
			margin: 0;
		}
	}

	@include break(medium) {
		.cards {
			display: grid;
		}

		&.cards-listing-three-col {
			.cards {
				grid-template-columns: 1fr 1fr 1fr;
			}
		}

		&.cards-listing-two-col {
			.cards {
				grid-template-columns: 1fr 1fr;
			}
		}
	}

	&.cards-listing-two-col {
		background-color: $black;

		.cards {
			article {
				@include flexbox(100%);

				&:nth-of-type(odd) {
					.card {
						background-color: $black;

						.card-wrap-inner {
							color: $white;
						}

						&.hovered {
							.card-wrap-inner {
								color: $black;
							}
						}
					}
				}

				&:nth-of-type(even) {
					.card {
						background-color: $white;

						.card-wrap-inner {
							color: $black;
						}
					}
				}

				@include break(medium) {
					height: 28rem;

					&:nth-of-type(1),
					&:nth-of-type(4),
					&:nth-of-type(7),
					&:nth-of-type(10) {
						.card {
							background-color: $blue;
							.card-wrap-inner {
								color: $black;
							}
						}
					}
					&:nth-of-type(2),
					&:nth-of-type(5),
					&:nth-of-type(8),
					&:nth-of-type(11) {
						.card {
							background-color: $grey;
							.card-wrap-inner {
								color: $black;
							}
						}
					}
					&:nth-of-type(3),
					&:nth-of-type(6),
					&:nth-of-type(9),
					&:nth-of-type(12) {
						.card {
							background-color: $white;
							.card-wrap-inner {
								color: $black;
							}
						}
					}
				}

				@include break(large) {
					&:nth-of-type(odd) {
						// margin-top: -6rem;
						margin-top: -8rem;
					}
				}

				.card {
					@include break(tablet) {
						display: flex;
						flex-flow: row;
					}

					@include break(tablet)

					&-image {
						// @include flexbox(40%);

						@include break(tablet) {
							@include flexbox(50%);
							min-height: 100%;
						}

						@include break(medium) {
							@include flexbox(45%);
						}

						@include break(large) {
							@include flexbox(40%);
						}
					}

					&-wrap {
						// @include flexbox(60%);

						@include break(tablet) {
							@include flexbox(50%);
						}

						@include break(medium) {
							@include flexbox(55%);
						}

						@include break(large) {
							@include flexbox(60%);
						}

						&.no-image {
							@include flexbox(100%);

							@include break(large) {
								@include flexbox(75%);
							}
						}
					}
				}
			}
		}
	}

	&.cards-listing-three-col {
		background-color: $black;

		&:after {
			background-color: $black;
			content: '';
			height: 66rem;
			position: absolute;
			bottom: 0;
			width: 100%;
			z-index: 1;
			display: none;

			@include break(medium) {
				// display: block;
			}
		}

		.cards {
			article {
				&:nth-of-type(odd) {
					.card {
						background-color: $black;

						.card-wrap-inner {
							color: $white;
						}

						&.hovered {
							.card-wrap-inner {
								color: $black;
							}
						}
					}
				}

				&:nth-of-type(even) {
					.card {
						background-color: $white;

						.card-wrap-inner {
							color: $black;
						}
					}
				}

				@include break(tablet) {
					&:nth-of-type(1),
					&:nth-of-type(4),
					&:nth-of-type(5),
					&:nth-of-type(8) {
						.card {
							background-color: $black;

							.card-wrap-inner {
								color: $white;
							}

							&.hovered {
								.card-wrap-inner {
									color: $black;
								}
							}
						}
					}

					&:nth-of-type(2),
					&:nth-of-type(3),
					&:nth-of-type(6),
					&:nth-of-type(7) {
						.card {
							background-color: $white;

							.card-wrap-inner {
								color: $black;
							}
						}
					}
				}

				@include break(medium) {
					height: 54rem;
					max-width: 100%;
					width: 100%;

					&:nth-of-type(1),
					&:nth-of-type(7),
					&:nth-of-type(5),
					&:nth-of-type(8) {
						margin-top: -8rem;
					}

					&:nth-of-type(3) {
						/* margin-top: 8rem; */
					}

					&:nth-of-type(4),
					&:nth-of-type(7) {
						margin-top: -16rem;
					}

					&:nth-of-type(6),
					&:nth-of-type(9) {
						margin-top: 0;
					}

					&:nth-of-type(1),
					&:nth-of-type(5),
					&:nth-of-type(9) {
						.card {
							background: $black;

							.card-wrap-inner {
								color: $white;
							}

							.metatag:not(:last-of-type) {
								border-color: $white;
							}

							&.hovered {
								.card-wrap-inner {
									color: $black;
								}
							}
						}
					}

					&:nth-of-type(2),
					&:nth-of-type(6),
					&:nth-of-type(7) {
						.card {
							background: $white;

							.card-wrap-inner {
								color: $black;
							}
						}
					}

					&:nth-of-type(3),
					&:nth-of-type(4),
					&:nth-of-type(8) {
						.card {
							background: $grey;

							.card-wrap-inner {
								color: $black;
							}

							.metatag:not(:last-of-type) {
								border-color: $white;
							}

							&.hovered {
								.card-wrap-inner {
									color: $black;
								}
							}
						}
					}
				}

				&:nth-of-type(9) {
					display: none;

					@include break(medium) {
						display: flex;
					}
				}
			}
		}
	}

	article {
		.card {
			margin: 0;

			&-image {
				border-radius: 0;
				overflow: hidden;
				min-height: 250px;
				max-height: 300px;

				@include break(medium) {
					min-height: 350px;
					max-height: 400px;
				}

				@include break(large) {
					min-height: 400px;
					max-height: 450px;
				}

				img {
					@extend %transition;
				}
			}

			&-wrap-inner {
				padding: $gap * 2 $gap * 2 $gap * 2.5;

				@include break(medium) {
					padding: $gap * 2.75 $gap * 3 $gap * 3.5;
				}
			}

			&-header {
				margin-top: $gap;

				&-date {
					margin-top: $gap;
				}

				&-title {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 4; /* number of lines to show */
					-webkit-box-orient: vertical;
				}
			}

			&-summary {
				margin-top: $gap / 1.5;

				p {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 4; /* number of lines to show */
					-webkit-box-orient: vertical;

					@include break(tablet) {
						-webkit-line-clamp: 6; /* number of lines to show */
					}

					@include break(medium) {
						-webkit-line-clamp: 5; /* number of lines to show */
					}
				}
			}

			.tags {
				border: 0;
				padding: 0;

				.metatag {
					@include break(medium) {
						display: inline-block;
					}

					&-link {
						@include font-size(14);
						@extend %transition;
						color: inherit;

						&:hover {
							color: $secondary;
						}

						@include break(medium) {
							@include font-size(16);
						}
					}

					@include break(medium) {
						&:not(:last-of-type) {
							&:after {
								content: '|';
								margin-left: $gap / 2;
								margin-right: $gap / 2;
							}
						}
					}
				}
			}

			.card-featured {
				@extend %transition;
				@include font-size(16);
				background-color: $yellow;
				// bottom: 0;
				color: $black;
				left: $gap * 2;
				text-transform: uppercase;
				top: 0;
				z-index: 1;

				@include break(medium) {
					@include font-size(20);
					left: $gap * 3;
				}
			}

			&.hovered {
				background-color: $yellow !important;
				transform: scale(1);
				z-index: 1;

				.metatag:not(:last-of-type) {
					border-color: $black !important;
				}

				.card-image {
					border-radius: 0;

					.card-featured {
						transform: scale(1.05);
						z-index: 2;
					}

					img {
						transform: scale(1.035);
					}
				}
			}
		}
	}
}
