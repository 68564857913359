.latest {
	overflow: visible;
	z-index: 1;
	position: relative;

	.home & {
		padding-bottom: 0;
	}

	.page-builder &,
	.single-post & {
		padding: 0;

		@include break(medium) {
			&+.section {
				// padding-top: $gap * 8;
			}
		}
	}

	article {
		margin: 0;

		.card {
			background-color: $white;
			overflow: visible;

			&.hovered,
			&:focus-within {
				background: $yellow !important;

				* {
					color: black !important;
				}

				&:before,
				&:after {
					background: $yellow;
				}
			}
		}

		&:first-of-type {
			flex: 0 100%;
			max-width: 100%;

			@include break(medium) {
				order: 0;
				flex: 0 50%;
				max-width: 50%;
			}

			.home & {
				.card {
					background-color: transparent;
					* {
						color: white;
					}
				}
			}

			.card {
				position: relative;

				@include break(medium) {
					&:before {
						content: '';
						height: 100%;
						width: 3000px;
						left: -3000px;
						top: 0;
						position: absolute;
						background: inherit;

						.home & {
							right: -3000px;
							right: auto;
						}
					}
				}
			}

		}

		&:nth-of-type(2) {
			.card {
				background: $grey;
				position: relative;

				* {
					color: black;
				}

				@include break(medium) {
					&:before {
						content: '';
						height: 100%;
						width: 3000px;
						right: -3000px;
						top: 0;
						position: absolute;
						background: inherit;

						.home & {
							left: -3000px;
							right: auto;
						}
					}
				}

			}
		}

		&:nth-of-type(3) {
			.card {
				position: relative;
				background: $black;

				* {
					color: white;
				}

				@include break(medium) {
					&:after {
						content: '';
						height: 100%;
						width: 3000px;
						left: -3000px;
						top: 0;
						position: absolute;
						background: inherit;

						.home & {
							right: -3000px;
							left: auto;
						}
					}
				}

				&-arrowcircle {
					background: $white;

					&:before {
						background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1L33 16L18 31' stroke='black' stroke-width='2'/%3E%3Cpath d='M0 16L33 16' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
					}
				}

				&.hovered {
					.card-arrowcircle {
						background: $black;

						&:before {
							background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1L33 16L18 31' stroke='white' stroke-width='2'/%3E%3Cpath d='M0 16L33 16' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
						}
					}
				}
			}
		}

		&:nth-of-type(4) {
			.card {
				position: relative;

				* {
					color: black;
				}

				@include break(medium) {
					&:before {
						content: '';
						height: 100%;
						width: 3000px;
						right: -3000px;
						top: 0;
						position: absolute;
						background: inherit;

						.home & {
							left: -3000px;
							right: auto;
						}
					}
				}

			}
		}

		.page-builder &,
		.single-post & {
			background: white;

			@include break(medium) {

				&:nth-of-type(odd) {
					.card-wrap-inner {
						padding: $gap * 5 $gap * 5 $gap * 5 $gap * 7;
					}
				}

				&:nth-of-type(even) {
					@include break(medium) {
						margin-bottom: -$gap * 8;
						margin-top: $gap * 8;
					}
				}

			}

		}
	}

	&-block {
		padding: $gap * 1;
		align-items: flex-end;
		order: -1;
		flex: 0 100%;
		max-width: 100%;

		@include break(medium) {
			padding: $gap * 4;
			order: 0;
			flex: 0 50%;
			max-width: 50%;
		}

		.button {
			margin-left: 0;

			@include break(medium) {
				margin-left: auto;
				margin-right: 0;
			}
		}
	}

	&+.cta-outer {
		z-index: 0;
	}



	.tags {
		border: 0;
		padding: 0;

		.metatag {
			@include break(medium) {
				display: inline-block;
			}

			&-link {
				@include font-size(14);
				@extend %transition;
				color: inherit;

				&:hover {
					color: $secondary !important;
				}

				@include break(medium) {
					@include font-size(16);
				}
			}

			@include break(medium) {
				&:not(:last-of-type) {
					&:after {
						content: '|';
						margin-left: $gap / 2;
						margin-right: $gap / 2;
					}
				}
			}
		}
	}
}
