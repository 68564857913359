/* ++++++++++++++++++++
+++ LAYOUT: SECTION +++
++++++++++++++++++++ */

// A section is a grouping of content, typically with a header, possibly with a footer. A '.section' with a '.container' results in a consistent paddings, margins and max-width for content while allowing for full width background colors on the section and centred background colors on the container.

.section {
	@extend %section;

	&.primary {
		background: $primary;
	}

	&.secondary {
		background: $secondary;
	}

	&.dark {
		background: $grey15;

		.section-header-title {
			color: $white;
		}
	}

	&.grey {
		background: $grey;

		@include break(medium) {
			margin-top: -2rem;
		}
	}

	&.black {
		background: $black;
		color: white;

		.section-header-title {
			color: $white;
		}
	}

	&.purple {
		background: $purple;
		color: white;

		.section-header-title {
			color: $white;
		}
	}

	&.primary,
	&.secondary,
	&.dark {

		.section-header,
		.section-footer,
		.main-header {
			color: $white;
		}

		&.wysiwyg,
		&.statistic-outer,
		&.download-outer,
		&.pullquote-outer,
		&.profile-outer {
			color: $white;
		}

		&.listing {
			.card-wrap {
				border-top: solid 5px $white;

				&-inner {
					color: $white;
				}
			}
		}
	}

	&.subnav {
		background: $white;
	}

	&-wysiwyg {
		&-container {
			&-content {
				margin-top: 2rem;
			}
		}
	}

	&-header {
		margin: 0 auto 0;
		padding-top: 3rem;

		@include break(small) {
			margin: 0 auto $gap * 2;
		}

		&-title {
			@include font-size(30);
			font-weight: $normal;
			line-height: 110%;
			margin-left: 0;
			max-width: 100%;
			margin-top: 2.5rem; // was margin: 0;
			color: $black;
			direction: ltr;

			+.section-header-summary {
				margin-top: $gap * 2;
				direction: ltr;
			}

			@include break(medium) {
				max-width: 60%;
				@include font-size(50);

				.profile-outer &,
				.timeline-outer & {
					@include font-size(30);
				}
			}

			@include break(large) {
				max-width: 43%;
				@include font-size(70);

				.profile-outer &,
				.timeline-outer & {
					@include font-size(40);
				}
			}

		}

		&-summary {
			@include font-size(18);
			margin-left: 0;
			font-family: $font-body-human;

			@include break(small) {
				@include font-size(22);
			}

			@include break(medium) {
				padding: 0 0 $gap;
				width: $gap * 40;
			}
		}

		.resources & {
			margin-bottom: $gap * 2;

			@include break(medium) {
				margin-bottom: $gap * 5;
			}
		}
	}

	&-footer {
		@extend %container;
		margin-top: $gap;

		@include break(small) {
			margin-top: $gap * 3;
		}

		.profile-outer & {
			text-align: center;
			margin-top: $gap * 2;
		}
	}

	&.listing {
		.card-image {
			display: none;
		}
	}
}

.container {
	@extend %container;

	&.centered {
		text-align: center;
	}

	&.small {
		max-width: $two-thirds-column;
	}

	&.white,
	&.grey,
	&.dark,
	&.primary,
	&.secondary {
		padding: $gap;

		@include break(small) {
			padding: $gap * 2;
		}

		@include break(large) {
			border-radius: $corner;
			max-width: $page-width - $gap * 4;
		}
	}

	&.dark,
	&.primary,
	&.secondary {
		color: $white;
	}

	&.white {
		background: $white;
	}

	&.grey {
		background: $grey90;
	}

	&.dark {
		background: $grey15;
	}

	&.primary {
		background: $primary;
	}

	&.secondary {
		background: $secondary;
	}
}
