/* ++++++++++++++++
+++ LAYOUT: CTA +++
++++++++++++++++ */

.cta {
	&-outer {
		background-color: $primary;
		position: relative;
		padding: $gap * 4 0;

		@include break(medium) {
			padding: $gap * 8 0;
		}

		&.full-width-image {
			padding: 0;

			@include break(tablet) {
				min-height: 80vh;
			}

			.cta-image {
				position: relative;

				@include break(tablet) {
					position: absolute;
				}
			}
		}

		&.site-width-image {
			padding: 0;

			@include break(tablet) {
				min-height: 60vh;
			}

			@include break(large) {
				margin: 0 auto;
				width: $page-width;
			}

			.cta-image {
				position: relative;

				@include break(tablet) {
					position: absolute;
				}
			}
		}

		.cta-image {
			// +.cta-inner {
			// 	background-color: rgba($white, 0.8);
			// 	border-radius: $corner;
			// 	box-shadow: $shadow;
			// 	color: $black;
			// 	margin: 4vh;

			// 	@include break(small) {
			// 		margin: 8vh;
			// 	}

			// 	@include break(medium) {
			// 		margin: 8vh auto;
			// 	}
			// }
		}

		.cta-inner {
			position: relative;
			z-index: 1;
			@extend .container;
			margin-bottom: $gap * 10;

			@include break(medium) {
				width: 50%;
				margin-left: 0;
				margin-bottom: $gap * 20;
			}
		}

		&+.statistic-outer,
		&+.timeline-outer,
		&+.profile-outer {
			overflow: visible;
			position: relative;
			padding-top: 1rem;

			@include break(medium) {
				padding-top: 0;
			}

			&:before {
				content: '';
				height: $gap * 4;
				width: 50%;
				position: absolute;
				top: -$gap * 4;
				left: 0;
				background: inherit;
				display: none;

				@include break(medium) {
					top: -$gap * 8;
					height: $gap * 8;
					display: block;
				}
			}

			&:after {
				content: '';
				height: $gap * 4;
				width: 50%;
				position: absolute;
				bottom: 0;
				right: 0;
				background: inherit;
				display: none;

				@include break(medium) {
					bottom: -$gap * 8;
					height: $gap * 8;
					display: block;
				}
			}
		}

		&+.wysiwyg {
			overflow: visible;
			position: relative;

			&:before {
				content: '';
				height: $gap * 4;
				width: 50%;
				position: absolute;
				top: -$gap * 4;
				left: 0;
				background: inherit;

				@include break(medium) {
					top: -$gap * 8;
					height: $gap * 8;
				}
			}
		}

		&+.latest {
			@include break(medium) {
				margin-top: -$gap * 8;
			}
		}
	}

	&-summary {
		padding: $gap * 1.5 0 $gap * 2;
	}

	&-title {

		.white-text & {
			color: white;
		}

		.black-text & {
			color: $black;
		}

		&+.button-white {
			margin-top: $gap * 2;
		}
	}

	&-image {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			@include object-fit(16/9, 100%);
		}
	}

	&-caption {
		background-color: $primary;
		color: $white;
		font-size: 0.8em;
		padding: $gap / 1.5 $gap * 1.5;


		@include break(tablet) {
			bottom: 0;
			left: 0;
			position: absolute;
		}
	}
}
