/* +++++++++++++++++++
+++ LAYOUT: TABLES +++
+++++++++++++++++++ */

.content {
	table {
		@extend %font-body;
		display: inline-block;
		font-variant-numeric: lining-nums tabular-nums;
		height: auto !important;
		max-width: 100%;
		overflow-x: auto;
		width: auto !important;

		caption {
			background-color: $grey95;
			display: inline-block;
			padding: $gap / 2 $gap / 1.5;
			text-align: left;
		}
	}

	thead,
	tfoot,
	tr {
		&:nth-child(even) {
			background: black;

			.single-post & {
				color: $white;
			}
		}
	}

	thead {
		tr {
			td {
				background-color: $primary;
				color: $white;
			}
		}
	}


	th,
	td {
		border: solid 1px $grey80;
		padding: $gap / 4 $gap / 2;
		vertical-align: top;
	}

	thead td,
	tfoot td,
	th {
		font-weight: $bold;
	}
}
