//++++++++++++++++++++++++
//+++ HELPERS: BUTTONS +++
//++++++++++++++++++++++++

// Button mixin
%button {
	@extend %transition;
	@extend %font-body;
	// border-radius: $corner;
	font-weight: $bold;
	padding: $gap $gap * 2;
	text-align: center;
	border-radius: 50px;
	text-transform: uppercase;
	@include font-size(16);
	display: inline-block;

	@include break('small') {
		width: auto;
	}
}

.button {
	@extend %button;
	background: $black;
	color: $white;
	position: relative;
	padding-left: 80px;

	&:hover {
		background: $yellow;
		color: $black;
	}

	&:active {
		background: $secondary-light;
	}

	&:before {
		content: '';
		height: 35px;
		width: 37px;
		background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1L33 16L18 31' stroke='white' stroke-width='2'/%3E%3Cpath d='M0 16L33 16' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
		background-size: cover;
		position: absolute;
		left: 10px;
		top: calc(50% - 18.5px);
		transition: ease 0.3s;
	}

	&:hover {
		&:before {
			background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1L33 16L18 31' stroke='black' stroke-width='2'/%3E%3Cpath d='M0 16L33 16' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
		}
	}

	.black &,
	.purple &,
	.grey &,
	&-white {
		background: white;
		color: $black;

		&:before {
			background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1L33 16L18 31' stroke='black' stroke-width='2'/%3E%3Cpath d='M0 16L33 16' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
		}

		&:hover {
			background: $yellow;
		}
	}

	&.plain {
		padding: $gap / 1.5 $gap * 1.5;
		margin: 0 5px;

		&:before {
			display: none;
		}

		&.black {
			background: $black;
			color: white;
		}

		&.white {
			background: white;
			color: $black;
		}

		&:hover {
			background: $yellow;
			color: $black;
		}
	}

	&.popup-video {
		min-height: 56px;

		&:before {
			background-image: url("data:image/svg+xml,%3Csvg width='24' height='28' viewBox='0 0 24 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.87564L22 14L1 26.1244L1 1.87564Z' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
			background-size: cover;
			width: 30px;
			left: 20px;
			// position: absolute;
			// top: 50%;
			// left: 50%;
			// transform: translate(-50%, -50%);
		}

		&:hover {
			&:before {
				background-image: url("data:image/svg+xml,%3Csvg width='24' height='28' viewBox='0 0 24 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.87564L22 14L1 26.1244L1 1.87564Z' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
			}
		}
	}
	// class below added in partials/media-items.twig in media element block to center play button with no text
	&.youtube-video-btn {
		min-height: 56px;

		&:before {
			background-image: url("data:image/svg+xml,%3Csvg width='24' height='28' viewBox='0 0 24 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.87564L22 14L1 26.1244L1 1.87564Z' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
			background-size: cover;
			// width: 30px;
			// left: 20px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&:hover {
			&:before {
				background-image: url("data:image/svg+xml,%3Csvg width='24' height='28' viewBox='0 0 24 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.87564L22 14L1 26.1244L1 1.87564Z' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
			}
		}
	}
}

.btn {
	a {
		@extend .button;
	}
}

.btn-secondary,
.button-secondary {
	@extend %button;
	box-shadow: inset 0 0 0 $line rgba($black, 0.2);

	&:hover {
		box-shadow: inset 0 0 0 $line currentColor;
	}

	&:active {
		background: rgba($black, 0.1);
	}
}
