.gradient {

	&-1 {
		background: linear-gradient(180deg, #FEEB4B 0%, #F9C438 26.42%, #F27824 57.15%, #F42F56 64.44%, #FAC840 72.78%, #FEEB4B 82.15%, #FEEB4B 100%);
	}

	&-2 {
		background: linear-gradient(180deg, #FEEB4B 0%, #FEE84A 62.48%, #F5992C 79.55%, #F27724 84.76%, #FDE843 93.09%);
	}

	&-3 {
		background: linear-gradient(180deg, #FAEA44 0%, #F6E945 27.46%, #99C756 62.48%, #6DBE60 74.86%, #52C0D0 84.76%, #EBE54A 93.09%);
	}

	&-4 {
		background: linear-gradient(180deg, #63C8F6 0%, #63C7ED 48.82%, #66BF63 67.05%, #63C8F6 85.8%, #4CC0F0 100%);
	}

	&-5 {
		background: linear-gradient(180deg, #4CC0F4 0%, #50C1EE 54.55%, #94D872 71.21%, #FEEB4B 81.11%, #E3E14C 84.95%, #B8D04E 86.04%, #6FBE5F 100%);
	}

	&-6 {
		background: linear-gradient(180deg, #4CC0F4 0%, #56B6EE 44.81%, #808BD7 59.65%, #A664C1 67.36%, #F32C59 79.55%, #D2387E 87.84%, #54A3E3 93.32%);
	}

	&-7 {
		background: linear-gradient(180deg, #F42F5D 0%, #F3492D 54.55%, #F16325 71.21%, #F27825 78.56%, #FDE743 85.81%, #F6A62F 87.86%, #F17323 100%);
	}

	&-8 {
		background: linear-gradient(180deg, #6FBE5F 0%, #A6C953 62.48%, #EAE245 76.54%, #FEEB4B 80.9%, #86D977 84.53%, #4CC0F4 88.89%, #4CC0F4 100%);
	}

	&-9 {
		background: linear-gradient(180deg, #47108B 0%, #47118D 43.21%, #373CD7 55.6%, #4CC0F4 64.06%, #4CC0F4 69.98%, #3C80D8 72.78%, #3E2FCD 78.99%, #47108B 100%);
	}

}
