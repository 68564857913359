/* +++++++++++++++++++
+++ LAYOUT: HEADER +++
+++++++++++++++++++ */

// Site header bar
.header {
	@extend %flex;
	// background: $header-color;
	// flex-flow: column-reverse;
	position: absolute;
	padding: $gap 0;
	width: 100%;
	z-index: 50;
	// transition: ease 0.3s;

	@include break(medium) {
		padding: $gap * 2 0;

		// .nav-showing & {
		// 	background: $white;
		// }
	}

	.container {
		display: flex; // basic flex for iphone5 and smaller.
		justify-content: space-between;
		align-items: center;
	}

	&-inner {
		@extend %flex;
		@include flexbox(100%);
		justify-content: space-between;
		margin: 0;
		padding: $gap;
	}

	&-logo {
		margin-left: 0;
		display: block;
		order: -2;

		@include break(medium) {
			order: 0;
		}

		&-svg {
			height: 80px;
			width: 80px;
			display: block;

			.cls-1 {
				fill: $yellow;
				transition: ease 0.3s 0.3s;

				@include break(medium) {
					transition: ease 0.3s;
				}

				.nav-showing & {
					fill: $black;
					transition: ease 0.3s;
				}

				.logo-black & {
					fill: $black;
				}
			}

			.cls-2 {
				fill: $black;
				transition: ease 0.3s 0.3s;

				@include break(medium) {
					transition: ease 0.3s;
				}

				.nav-showing & {
					fill: $white;
					transition: ease 0.3s;
				}

				.logo-black & {
					fill: $white;
				}
			}

			@include break(medium) {
				height: 120px;
				width: 120px;
			}
		}
	}

	&-links {
		margin: 0 0 0 auto;
		display: none;

		@include break(medium) {
			display: block;
			padding-left: $gap / 2;

			.button.white {
				.nav-showing & {
					background: $pink;
					color: white;

					&:hover,
					&:focus {
						background: $yellow;
						color: $black;
					}
				}
			}
		}
	}

	&-nav {
		margin-right: 0;

		&-list {
			display: none;

			@include break(medium) {
				display: block;
				margin: 0;
			}
		}
	}

	&-subnav {
		&-inner {
			@include flexbox(100%);
			background-color: $grey90;
			display: none;
			padding: $gap / 4 $gap;
			text-align: right;
			width: 100%;

			@include break(medium) {
				display: block;
			}
		}

		@include break(medium) {
			display: inline-block;

			&-list {
				text-align: right;

				.subnav-item {
					display: inline-block;

					.nav-item-link {
						font-size: 0.9em;
						margin: 0 $gap / 2;
					}
				}
			}
		}
	}

	&-language {
		margin: 0;
		order: -1;

		@include break(medium) {
			order: 0;
		}

		select {
			background-color: transparent;
			border: solid 2px $black;
			border-radius: 50px;
			width: auto;
			padding-right: 40px;
			color: black;
			font-weight: bold;
			background-position: calc(100% - 10px) center;
			background-size: 20px;
			@include font-size(14);
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='15'%3E%3Cpath fill='none' stroke='%23000' stroke-width='2' d='M1 0l14.285 14.285L29.57 0'/%3E%3C/svg%3E");

			.nav-white & {
				border: solid 2px $white;
				color: $white;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='15'%3E%3Cpath fill='none' stroke='%23FFF' stroke-width='2' d='M1 0l14.285 14.285L29.57 0'/%3E%3C/svg%3E");
			}

			.nav-showing & {
				border: solid 2px $black;
				color: $black;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='15'%3E%3Cpath fill='none' stroke='%23000' stroke-width='2' d='M1 0l14.285 14.285L29.57 0'/%3E%3C/svg%3E");
			}

			@include break(medium) {
				@include font-size(16);
			}
		}
	}

	&-menu-trigger {
		margin: 0;

		.menu-button {
			-webkit-appearance: none;
			background: none;
			font-family: $font-body;
			@include font-size(18);
			text-transform: uppercase;
			font-weight: $bold;
			position: relative;
			padding-left: 40px;
			padding-right: 0px;
			margin: 0 0 0 15px;
			color: $black;

			.nav-white & {
				color: white;

				&:hover {
					text-decoration: none;

					p {
						color: black;
					}

					span {
						background: black;
					}
				}
			}

			.home & {
				&:hover {
					p {
						color: $secondary;
					}

					span {
						background: $secondary;
					}

					@include break(medium) {
						p {
							color: black;
						}

						span {
							background: black;
						}
					}
				}
			}

			.page-builder & {
				p {
					color: $white;
				}

				span {
					background: $white;
				}

				@include break(medium) {
					p {
						color: $white;
					}

					span {
						background: $white;
					}
				}

				&:hover {
					p {
						color: $secondary;
					}

					span {
						background: $secondary;
					}

					@include break(medium) {
						p {
							color: $white;
						}

						span {
							background: $white;
						}
					}
				}
			}

			@include break(medium) {
				margin: 0 30px 0 15px;
			}

			&.desktop {
				display: none;

				@include break(medium) {
					display: block;
				}
			}

			&.mobile {
				display: block;
				order: 1;

				@include break(medium) {
					display: none;
				}
			}

			&:hover {
				text-decoration: none;

				p {
					color: $white;
				}

				span {
					background: $white;
				}
			}

			p {
				transition: ease 0.3s;

				.nav-showing & {
					margin-left: -3px;
					color: black !important;
				}
			}

			.nav-showing & {
				color: black !important;
			}

			span {
				position: absolute;
				left: 0;
				height: 2px;
				width: 30px;
				background: $black;
				transition: ease 0.3s;

				.nav-white & {
					background: white;
				}

				.nav-showing & {
					background: black !important;
				}

				&:first-of-type {
					top: 0;

					.nav-showing & {
						top: calc(50% - 1px);
						transform: rotate(45deg);
					}
				}

				&:nth-of-type(2) {
					top: calc(50% - 1px);

					.nav-showing & {
						opacity: 0;
					}
				}

				&:last-of-type {
					bottom: 0;

					.nav-showing & {
						bottom: calc(50% - 1px);
						transform: rotate(-45deg);
					}
				}
			}
		}
	}

	&-navigation {
		position: absolute;
		top: 184px;
		width: 100%;
		height: calc(100vh - 184px);
		z-index: 1;
		padding: 0;
		opacity: 0;
		pointer-events: none;
		z-index: map-get($zindex, header);

		@include break(medium) {
			.nav-showing & {
				opacity: 1;
				pointer-events: auto;
			}
		}

		&-primary {
			position: relative;

			ul {
				@extend %flex;
				justify-content: space-between;
				margin-left: 0;
				// width: 100%;

				li {
					flex: 1;
					max-width: 220px;
					margin: 0;
					padding-right: $gap;

					@include break(large) {
						padding-right: 0;
					}

					&:last-of-type {

						@include break(large) {
							margin-right: 15%;
						}

						a.parent {
							max-width: 150px;

							&:hover,
							&:focus,
							&.current {
								max-width: 150px;
							}
						}
					}

					a {
						display: block;
						font-family: $font-body;
						font-weight: $medium;
						@include font-size(16);
						color: white;
						transition: ease 0.3s;

						@include break(large) {
							@include font-size(18);
						}

						// &:hover {
						// 	color: $yellow;
						// }

						&+a {
							margin-top: $gap * 1.5;
						}

						&.parent {
							@include font-size(24);
							max-width: 170px;
							margin: 0;
							line-height: 34px;

							@include break(large) {
								@include font-size(28);
							}
						}

						&:hover,
						&:focus,
						&.current {
							padding-left: $gap;
							border-left: solid 3px $yellow;
							color: $yellow;

							&.parent {
								max-width: 190px;
							}
						}
					}
				}
			}
		}

		&-secondary {
			a {
				@include font-size(20);
				font-weight: $bold;
				margin-left: 0;
				display: table;
				color: $black;

				@include break(large) {
					@include font-size(26);
				}

				transition: ease 0.3s;

				&:hover,
				&:focus,
				&.current {
					padding-left: $gap;
					border-left: solid 6px $pink;
				}

				&+a {
					margin-top: $gap * 0.5;
				}
			}
		}

		&-search {
			margin-top: $gap * 0.5;

			&.search-showing {
				button.menu-item {
					display: none;
				}

				.search {
					display: block;
				}
			}

			button.menu-item {
				-webkit-appearance: none;
				@include font-size(20);
				font-weight: $bold;
				color: $black;
				background: none;
				padding: 0px;
				font-family: $font-body;
				font-weight: $medium;
				transition: ease 0.3s;

				@include break(large) {
					@include font-size(26);
				}

				&:hover,
				&:focus {
					padding-left: $gap;
					border-left: solid 6px $pink;
					text-decoration: none;
				}

			}

			.search {
				display: none;
				width: 100%;
				max-width: 300px;
				margin-left: -$gap;
				margin-top: $gap;

				&-input {
					border-radius: 0;
					padding-left: $gap * 3.5;
					height: $gap * 3;
					border: none;
				}

				&-button {
					right: auto;
					left: 0;
					border-radius: 0px;
					height: $gap * 3;
					width: $gap * 3;
					background: white;
					border: none;

					&:after,
					&:before {
						display: none;
					}
				}

				&-icon {
					height: 26px;
					width: 26px;
					fill: black;
					right: calc(50% - 13px);
				}
			}
		}

		.container {
			position: relative;
			z-index: 1;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}

		&-primary {
			width: 65%;
			margin: 0;
			padding: $gap * 2 0 0 0;

			@include break(large) {
				width: 65%;
			}
		}

		&-secondary {
			width: 32.5%;
			padding: $gap * 2 $gap $gap $gap * 2;

			@include break(large) {
				width: 32.5%;
			}

			a {
				width: 100%;
			}
		}

		&-black {
			position: absolute;
			height: calc(100% - 100px);
			left: 0;
			top: 0;
			width: 65%;
			background: $black;

			@include break(xlarge) {
				width: 60%;
			}
		}

		&-grey {
			position: absolute;
			height: 100%;
			right: 0;
			top: 0;
			width: 35%;
			background: $grey;
			z-index: -1;

			@include break(xlarge) {
				width: 40%;
			}
		}

		&-image {
			height: 100px;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;

			@media screen and (max-height: 700px) {
				display: none;
			}

			img {
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}
	}

	.switcher {
		border-radius: 50px;
		margin: 0;
		max-width: 110px;
		padding-left: $gap;

		@include break(small) {
			max-width: 100%;
			padding-left: $gap / 2;
		}

		.selected:first-of-type {
			@extend %transition;
			@include font-size(14);
			background: transparent;
			background-color: transparent;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='15'%3E%3Cpath fill='none' stroke='%23000' stroke-width='2' d='M1 0l14.285 14.285L29.57 0'/%3E%3C/svg%3E");
			background-position: calc(100% - 10px) center;
			background-repeat: no-repeat;
			background-size: 20px;
			border: solid 2px $black;
			border-radius: 50px;
			color: black;
			font-family: $font-body;
			font-weight: bold;
			padding-right: 40px;
			text-transform: uppercase;
			width: auto;

			.nav-white & {
				border: solid 2px $white;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='15'%3E%3Cpath fill='none' stroke='%23FFF' stroke-width='2' d='M1 0l14.285 14.285L29.57 0'/%3E%3C/svg%3E");
				color: $white;
			}

			.nav-showing & {
				border: solid 2px $black;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='15'%3E%3Cpath fill='none' stroke='%23000' stroke-width='2' d='M1 0l14.285 14.285L29.57 0'/%3E%3C/svg%3E");
				color: $black;
			}

			@include break(medium) {
				@include font-size(16);
			}

			a {
				@extend %transition;
				border: 0;
				border-radius: 50px;
				color: $black;
				max-width: 3rem;
				overflow: hidden;
				padding: $gap * 0.6 $gap;
				text-overflow: ellipsis;
				white-space: nowrap;

				@include break(small) {
					max-width: 100%;
					overflow: auto;
					text-overflow: unset;
					white-space: normal;
				}

				&.open {
					border-radius: 0;
				}

				&:hover {
					background-color: transparent;
				}

				.nav-white & {
					color: $white;
				}

				.nav-showing & {
					color: $black;
				}

				&:after {
					display: none;
				}

				img {
					display: none;
				}
			}
		}

		.option {
			background-color: $white;
			border: 0;
			position: absolute;
			right: 0;

			@include break(small) {
				left: auto;
				right: auto;
				margin-top: 0;
			}

			img {
				display: none;
			}

			a {
				// background-color: $white;
				font-family: $font-body;
				padding: $gap / 1.25;
				text-transform: uppercase;

				&:hover {
					background-color: $secondary;
					color: $white;
				}
			}
		}

		.nturl {
			background-image: none !important;

			&:hover {
				background-image: none !important;
			}
		}

		&:hover {
			.selected {
				border-color: $white;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='15'%3E%3Cpath fill='none' stroke='%23FFF' stroke-width='2' d='M1 0l14.285 14.285L29.57 0'/%3E%3C/svg%3E");

				a {
					color: $white;
				}
			}

			.nav-white & {
				.selected {
					border-color: $black;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='15'%3E%3Cpath fill='none' stroke='%23000' stroke-width='2' d='M1 0l14.285 14.285L29.57 0'/%3E%3C/svg%3E");

					a {
						color: $black;
					}
				}
			}

			.page-builder & {
				@media (max-width: 960px) {
					.selected {
						border-color: $secondary;
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='15'%3E%3Cpath fill='none' stroke='%23FF1659' stroke-width='2' d='M1 0l14.285 14.285L29.57 0'/%3E%3C/svg%3E");

						a {
							color: $secondary;
						}
					}
				}
			}
		}
	}

}

// Navigation items in the header and footer
.nav-item {
	float: left;

	&:focus-within .dropdown,
	&:hover .dropdown {
		@include transition(opacity 0.2s 0.3s ease);
		opacity: 1;
		visibility: visible;

		.nav-item {
			float: none;
		}
	}

	&.hovered {
		.nav-item-link {
			box-shadow: inset 0 -2px 0 0 $primary;
		}

		.dropdown-item {
			.nav-item-link {
				box-shadow: none;
			}
		}
	}

	.button {
		margin-left: $gap;
	}

	&-link {
		@extend %text-hover;
		display: block;
		margin: 0 $gap;
		padding: $gap / 2 0;

		.social & {
			margin: 0;
			padding: $gap / 2 $gap;
		}

		&.current {
			box-shadow: inset 0 -2px 0 $secondary;
		}
	}

	&-arrow {
		width: $gap;
	}
}

// Responsive nav hamburger button
.menu-button {
	background-color: transparent;
	color: $black;
	cursor: pointer;
	font-size: 1.4em;
	font-weight: $bold;
	margin: $gap / 5 0 0 0;
	padding: 0 $gap * 1.5 0 0;
	position: relative;
	text-transform: uppercase;
	width: auto;

	&:hover {
		background-color: transparent;
		box-shadow: none;
		color: $secondary;
	}

	@include break(medium) {
		display: none;
	}

	.icon {
		@include centerer(false, true);
		height: 1em;
		right: 0;
		width: 1em;
	}
}

// Dropdown menu styling
.dropdown {
	@include transition(visibility 0s 0.4s ease, opacity 0.2s 0.2s ease);
	background: $header-color;
	border-radius: 0 0 $corner $corner;
	margin-top: 15px;
	opacity: 0;
	position: absolute;
	visibility: hidden;
}

// CMS tweaks
@if $wordpress {
	#wpadminbar {
		box-sizing: content-box;
		margin-top: 0;

		img {
			display: inline;
		}

		@media screen and (max-width: 600px) {
			position: fixed;
		}
	}
}

@if $drupal {
	.header-nav-outer {
		.nav-item-link {
			&.is-active {
				color: $grey15;
				font-weight: $bold;
			}
		}
	}
}
