.components {

	direction: ltr;

	.timeline-outer:last-child {
		overflow: visible;
		position: relative;

		&:after {
			content: '';
			height: $gap * 4;
			width: 50%;
			background: $white;
			position: absolute;
			bottom: -$gap * 4;
			right: 0;
			z-index: 1;
			display: none;

			@include break(medium) {
				bottom: -$gap * 8;
				height: $gap * 8;
				display: block;
			}
		}
	}

	.profile-outer:last-child {
		overflow: visible;
		position: relative;

		&:after {
			content: '';
			height: $gap * 4;
			width: 50%;
			background: $purple;
			position: absolute;
			bottom: -$gap * 4;
			right: 0;
			z-index: 1;
			display: none;

			@include break(medium) {
				bottom: -$gap * 8;
				height: $gap * 8;
				display: block;
			}
		}
	}

	.cta-outer:last-child {
		overflow: visible;
		position: relative;

		&:before {
			content: '';
			height: 30vw;
			width: 50%;
			position: absolute;
			bottom: -$gap * 4.5;
			right: 0;
			background-image: url('/assets/images/grid-dots-white.svg');
			background-size: contain;
			background-position: top center;
			z-index: 2;
			display: none;

			@include break(medium) {
				bottom: -$gap * 10;
				display: block;
			}
		}

		&:after {
			content: '';
			height: $gap * 4;
			width: 50%;
			background: $black;
			position: absolute;
			bottom: -$gap * 4;
			right: 0;
			z-index: 1;
			display: none;

			@include break(medium) {
				bottom: -$gap * 8;
				height: $gap * 8;
				display: block;
			}
		}
	}
}
