/* +++++++++++++++++++++++++
+++ LAYOUT: TEAM PROFILE +++
+++++++++++++++++++++++++ */

.profile {
	@extend %flex;
	align-items: flex-start;
	// padding: $gap * 2 0;
	width: calc(100% + 2rem);
	margin-left: -$gap;
	margin-right: -$gap;
	margin-top: $gap;

	@include break(medium) {
		margin-top: 0;
	}

	&-outer {
		overflow: visible;
		position: relative;

		&:after {
			content: '';
			height: $gap * 4;
			width: 50%;
			background: $purple;
			position: absolute;
			bottom: -$gap * 4;
			right: 0;
			z-index: 1;

			@include break(medium) {
				bottom: -$gap * 8;
				height: $gap * 8;
			}
		}

		&+.timeline-outer {
			padding-top: $gap * 6;

			@include break(medium) {
				padding-top: $gap * 8;
			}
		}
	}

	&-floatingname {
		pointer-events: none;
		background: $black;
		color: white;
		font-weight: $bold;
		max-width: 200px;
		text-transform: uppercase;
		@include font-size(12);
		padding: 3px;
		display: none;
		z-index: 100;

		@include break(medium) {
			display: block;
		}
	}

	&-overlay {
		@extend %transition;
		background-color: rgba($black, 0.7);
		height: 100%;
		opacity: 0;
		left: 0;
		position: fixed;
		top: 0;
		visibility: hidden;
		width: 100%;
		z-index: 99;

		&.active-profile {
			opacity: 1;
			visibility: visible;
		}
	}

	&-element {
		@extend %flex;
		@include flexbox(50%);
		align-items: flex-start;
		justify-content: center;
		text-align: center;
		// margin: 0 0 $gap * 2;
		padding: $gap;

		@include break(small) {
			@include flexbox(33.33%);
		}

		@include break(medium) {
			@include flexbox(20%);
			margin: 0;
		}

		@include break(large) {
			@include flexbox(16.6666666667%);
		}

		// &:nth-child(n+4) {
		// 	@include break(medium) {
		// 		margin-top: $gap * 3;
		// 	}

		// 	@include break(large) {
		// 		margin-top: 0;
		// 	}
		// }

		// &:nth-child(n+5) {
		// 	@include break(medium) {
		// 		margin-top: 0;
		// 	}

		// 	@include break(large) {
		// 		margin-top: $gap * 3;
		// 	}
		// }
	}

	&-item {
		margin: 0;
		width: 100%;
	}

	&-image {
		// border-radius: 100%;
		// box-shadow: 0 0 8px rgba($black, 0.2);
		display: inline-block;
		margin: 0;
		width: 100%;

		.with-summary & {
			@extend %image-hover;
			cursor: pointer;
		}

		img {
			margin: 0;
			width: 100%;
		}
	}

	&-wrap {
		margin: $gap 0 0 0;
		padding: 0 $gap;
		@include font-size(14);

		@include break(medium) {
			display: none;
		}
	}

	&-name {
		font-size: 1.3em;
	}

	&-title {
		font-size: 1em;
		font-weight: normal;
		padding-top: $gap / 1.5;
	}

	&-summary {
		@extend %transition;
		background-color: $white;
		box-shadow: $shadow;
		height: 100%;
		margin-top: 0;
		overflow: scroll;
		padding: $gap * 5 $gap * 2 $gap * 2;
		position: fixed;
		right: -90%;
		text-align: left;
		top: 0;
		width: 90%;
		z-index: 999;

		@include break(tablet) {
			right: -50%;
			width: 50%;
		}

		@include break(large) {
			right: -35%;
			width: 35%;
		}

		&.active-profile {
			right: 0;
		}

		&-copy {
			margin-top: $gap;
			color: $black;
		}

		.profile-summary-close {
			@extend %transition;
			color: $primary;
			cursor: pointer;
			position: absolute;
			right: $gap * 2;
			top: $gap * 2;
			z-index: 999;

			&:hover {
				color: $secondary;
				transform: rotate(90deg);
			}
		}

		&-inner {
			display: block;
			margin-top: 0;
			position: relative;
			z-index: 11;

			.profile-name {
				color: $primary;
				font-size: 1.3em;

				@include break(small) {
					font-size: 1.5em;
				}
			}

			p {
				color: $black;
				font-size: 0.9em;
				margin-top: 0;
				padding-top: $gap;

				@include break(small) {
					font-size: 1em;
				}
			}
		}
	}

	&-meta {
		margin-top: $gap;

		&-item {
			display: inline-block;
			padding: $gap / 4 0;

			&-link {
				@extend %transition;
				align-items: center;
				display: block;
				height: 40px;
				margin-right: $gap / 2;
				position: relative;
				width: 40px;
				background: $pink;
				color: white;

				.icon {
					@include centerer(true, true);
				}

				&:hover {
					background: $primary;

					.profile-meta-item-text {
						border-bottom: solid 2px $primary;
					}
				}

				&.mail {
					.icon {
						color: $white;
						height: $gap * 1.2;
						width: $gap * 1.2;
					}

					&:hover {}
				}
			}

			&-text {
				@extend %transition;
				color: $black;
				margin-left: $gap / 1.5;
			}
		}
	}

}
