/* +++++++++++++++++++++
+++ LAYOUT: TIMELINE +++
+++++++++++++++++++++ */

.current-button {
	// @extend %font-blocky-spaced;

	@include break(medium) {
		background-color: $secondary;
		color: $black;
		display: block;
		margin: 0 auto;
		border: $line;
		padding: $gap $gap * 3;
		position: relative;
		width: 250px;
		text-align: center;
		z-index: 2;

		&:hover {
			background-color: $primary;
			color: $black;
		}
	}
}

.timeline-outer {
	background-color: $white;
}

.timeline {
	background-color: transparent;
	margin: $gap * 2 0 0 0;
	// margin-bottom: $gap*4;
	// $line: 2px solid $color-gold;
	$line: 1px solid $black;
	height: auto;
	position: relative;

	@include break(medium) {
		margin: $gap * 5 0 0 0;
	}

	.container {
		max-width: $gap * 75;
	}

	&-inner {
		// @extend %container-inner;
	}

	&-line {
		display: none;
		position: absolute;
		width: 3px;
		background-color: $pink;
		left: calc(50% - 1.5px);
		// top: $gap * 4.5;
		top: 0;
		// height:calc(100% - #{$gap*25});
		height: calc(100% - #{$gap*1.25});
		margin: 0;
		// z-index: -1;

		@include break(medium) {
			display: block;
		}

		@include break(large) {
			// height:calc(100% - #{$gap*23});
		}
	}

	.event {
		position: relative;
		margin: 0;
		width: 100%;
		z-index: 1;

		@include break(medium) {
			border-top: 0;
			margin-top: -$gap;
		}

		// &:first-of-type {
		// 	@include break(medium) {
		// 		margin-top: $gap * 2;
		// 	}
		// }

		// &:last-of-type {
		// 	@include break(medium) {
		// 		margin-bottom: $gap * 4;
		// 	}
		// }

		&:nth-of-type(even) {
			@include break(medium) {
				justify-content: flex-end;
			}

			.event-inner:before {
				left: auto;
				right: 0;
			}
		}

		@include break(medium) {
			display: flex;
			justify-content: flex-start;
		}

		&-inner {
			margin: 0;
			position: relative;
			margin-top: $gap;
			padding-top: $gap;

			&:before {
				content: '';
				height: 3px;
				width: 100%;
				background: $black;
				position: absolute;
				top: 0;
				left: 0;

				@include break(medium) {
					width: 91%;
				}
			}

			&:hover {
				.event-link {
					color: $secondary;
				}
			}

			@include break(medium) {
				margin-top: $gap*2;
				padding-top: 0;
				width: 50%;
			}

			&.event-inner-current {
				background: $primary;
				color: $black;
				padding: $gap*2;

				.event-year {
					background: $primary;
					// border-color: $black;
				}
			}
		}

		&-content {
			@include break(medium) {
				padding-right: $gap*5;
				padding-top: $gap*2;
			}

			a {
				text-decoration: underline;
			}
		}

		&-content {
			color: $black;
			$gap: 1rem;

			&-day {
				display: block;
				margin: 0;
				padding-bottom: $gap;
				font-size: .8em;
			}

			&-date {
				font-weight: $bold;
				@include font-size(14);
				line-height: 130%;
				letter-spacing: 0.2em;
				text-transform: uppercase;
				margin-bottom: $gap;
				display: block;

				@include break(medium) {
					[dir="rtl"] & {
						margin: $gap;
					}
				}
			}

			&-title {
				@extend %h3;
				@include font-size(18);
				margin: 0;
				padding-bottom: $gap;

				@include break(medium) {
					[dir="rtl"] & {
						padding: 0 $gap $gap $gap;
					}
				}

				a {
					text-decoration: underline;

					&:hover {
						color: $secondary;
						text-decoration: underline;
					}
				}

				@include break(medium) {
					@include font-size(22);
				}

				.event-link {
					@extend %transition;
				}
			}

			&-summary {
				margin: 0;
				padding-bottom: $gap;
				@include font-size(22);
				font-family: $font-body-human;

				@include break(medium) {

					[dir="rtl"] & {
						padding: 0 $gap $gap $gap;
					}
				}
			}

			&-image {
				margin: 0;
				padding-bottom: $gap;

				img {
					@include object-fit(16/9, 100%);
					max-height: 300px;
					// width: 100%;
				}
			}
		}

		&-year {
			// @extend %font-blocky-spaced;
			margin-bottom: $gap;

			@include break(medium) {
				background-color: $pink;
				margin: 0;
				width: 80px;
				height: 80px;
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				right: -40px;
				top: -40px;
				text-align: center;
				z-index: map-get($zindex, overlay);
			}

			&-title {
				color: $pink;
				@include font-size(26);

				@include break(medium) {
					color: $white;
					@include font-size(18);
				}
			}
		}

		&:nth-of-type(even) {
			.event {
				&-content {
					@include break(medium) {
						padding-right: 0;
						padding-left: $gap*5;
					}
				}

				&-year {
					@include break(medium) {
						left: -40px;
					}
				}
			}
		}

		//end even
	}
}
