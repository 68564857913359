//+++++++++++++++++++++++++
//+++ HELPERS: WEBFONTS +++
//+++++++++++++++++++++++++

@font-face {
    font-family: 'Reckless';
    font-style: normal;
    font-weight: 400;
    // font-display: optional;
    src: url('../../assets/webfonts/Reckless-Regular.ttf') format('ttf'),
		url('../../assets/webfonts/reckless-regular-webfont.woff2') format('woff2'),
        url('../../assets/webfonts/reckless-regular-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Reckless';
    font-style: normal;
    font-weight: 600;
    // font-display: optional;
    src: url('../../assets/webfonts/Reckless-Semibold.ttf') format('ttf'),
		url('../../assets/webfonts/reckless-semibold-webfont.woff2') format('woff2'),
        url('../../assets/webfonts/reckless-semibold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'TWKEverett';
    font-style: normal;
    font-weight: 400;
    // font-display: optional;
    src: url('../../assets/webfonts/TWKEverett-Regular-web.ttf') format('ttf'),
        url('../../assets/webfonts/TWKEverett-Regular-web.woff2') format('woff2'),
        url('../../assets/webfonts/TWKEverett-Regular-web.woff') format('woff');
}

@font-face {
    font-family: 'TWKEverett';
    font-style: normal;
    font-weight: 600;
    // font-display: optional;
    src:url('../../assets/webfonts/TWKEverett-Medium-web.ttf') format('ttf'),
        url('../../assets/webfonts/TWKEverett-Medium-web.woff2') format('woff2'),
        url('../../assets/webfonts/TWKEverett-Medium-web.woff') format('woff');
}
