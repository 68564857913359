/* +++++++++++++++++
+++ LAYOUT: HERO +++
+++++++++++++++++ */

.hero {
	@extend %flex;
	position: relative;
	margin-top: 8rem;

	@include break(medium) {
		flex-direction: row-reverse;
		margin-top: 0;
		min-height: 300px;
		flex-wrap: wrap;

		.has-img & {
			min-height: 700px;
		}

		.hero-listing & {
			margin-top: 0;
			min-height: 550px;
		}

		.resource-single & {
			min-height: auto;
		}
	}

	&-outer {
		padding: 0;

		.resource-single & {
			@include break(large) {
				overflow: visible;
			}
		}

		// &.full {
		// 	.hero-image {
		// 		@include flexbox(100%);
		// 		height: 100%;
		// 		width: 100%;

		// 		@include break(tablet) {
		// 			left: 0;
		// 			position: absolute;
		// 			top: 0;
		// 		}

		// 		+.hero-info {
		// 			@include flexbox(100%);
		// 			background-color: rgba($white, 0.7);
		// 			box-shadow: $shadow;
		// 			position: relative;
		// 			text-align: center;
		// 			z-index: 2;

		// 			@include break(tablet) {
		// 				@include flexbox(80%);
		// 				border-radius: $corner;
		// 				margin: 10em auto;
		// 			}

		// 			@include break(medium) {
		// 				@include flexbox(50%);
		// 			}

		// 			.hero-inner {
		// 				// color: $black;
		// 				margin: 0;
		// 				// padding: 3rem;
		// 				width: auto;
		// 			}
		// 		}
		// 	}

		// 	.hero-summary {
		// 		p {
		// 			padding: 0;
		// 		}
		// 	}
		// }

		&.listing {
			text-align: center;

			.hero-info {
				background-color: $secondary;

				.hero-inner {
					margin: 0 auto;

					@include break(medium) {
						width: $page-width / 1.5;
					}

					.hero-title,
					.hero-summary {
						@include break(medium) {
							width: 100%;
						}
					}
				}
			}
		}
	}

	&-gradient {
		width: 100%;

		.container {
			.has-img & {
				@include break(large) {
					min-height: 407px;
				}
			}
		}
	}

	&-info,
	&-image {
		margin: 0;
	}

	&-info {
		@extend %flex;
		@include flexbox(100%);
		align-items: flex-end;
		background: $black;

		@include break(medium) {
			background: transparent;
		}

		a {
			margin-top: 2rem;
			text-decoration: underline;
		}

		.button {
			text-decoration: none;
		}
	}

	&-inner {
		background: none;
		color: $white;
		margin: 0;

		.single-post & {
			background: white;
			position: relative;

			&:before {
				content: '';
				height: 100%;
				width: 3000px;
				left: -3000px;
				background: white;
				top: 0;
				position: absolute;
			}

			&:after {
				content: '';
				height: 100%;
				width: 3000px;
				right: -3000px;
				top: 0;
				background: $black;
				position: absolute;
			}
		}

		@include break(medium) {
			margin: $gap * 10 auto auto auto;

			.single-post & {
				margin: auto;
			}

			.hero-listing & {
				margin: $gap * 5 auto auto auto;
			}
		}

		&-top {
			background: $white;
			width: 100%;
			padding: $gap;
			position: relative;

			@include break(medium) {
				margin: 15rem 0 0 0;
			}

			.has-img & {
				@include break(large) {
					margin-left: auto;
				}
			}

			.hero-listing & {
				background: $black;
			}

			&:before {
				content: '';
				height: 100%;
				width: 3000px;
				left: -1500px;
				position: absolute;
				background: inherit;
				top: 0;

				@include break(medium) {
					left: -3000px;
				}
			}

			@include break(medium) {
				width: 70%;
				padding: $gap * 2;
			}

			@include break(large) {
				width: 50%;
				padding: $gap * 2;
			}

			.hero-gradient & {
				position: absolute;
				bottom: 0;
				left: 0;
			}

			.resource-single & {
				position: relative;

				@include break(medium) {
					margin-top: $gap * 12;
				}

				@include break(large) {
					margin-top: $gap * 15;
				}
			}
		}

		&-bottom {
			background: $black;
			width: 100%;
			padding: $gap;

			.hero-listing & {
				background: $white;
				margin-right: 0;
				position: relative;
				width: auto;

				&:after {
					content: '';
					height: 100%;
					width: 2rem;
					right: -2rem;
					position: absolute;
					background: inherit;
					top: 0;

					@include break(medium) {
						width: 3000px;
						right: -3000px;
					}
				}
			}

			.resource-single & {
				margin-right: 0;
			}

			@include break(medium) {
				width: 70%;
				padding: $gap * 2;
			}

			@include break(large) {
				width: 60%;
				margin-left: $gap * 10;

				.single-post & {
					width: 65%;
					margin-left: auto;
					margin-right: 0;
				}
			}
		}
	}

	&-image {
		overflow: hidden;
		width: 100%;
		min-height: 150px;

		.has-img & {
			min-height: 350px;
		}

		@include break(medium) {
			min-height: 300px;
			position: absolute;
			top: 0;
			left: 0;

			.has-img & {
				min-height: 700px;
			}

			.single-post & {
				position: relative;
				margin-top: $gap * 11.5;
			}
		}

		img {
			@include object-fit(16/9, 100%);
		}

		.resource-single & {
			display: none;
			height: 407px;
			margin-left: $gap * 2;
			margin-top: 0;
			position: absolute;
			width: 300px;
			z-index: 1;

			@include break(large) {
				display: block;
				min-height: auto;
			}
		}

		&-responsive {
			background-color: $white;
			display: block;
			height: auto;
			padding: $gap * 3 0 0 0;
			// margin-bottom: -$gap * 2;

			@include break(large) {
				display: none;
			}

			+ .single .main-section {
				padding-top: 0;
			}

			img {
				height: 100%;
				height: 350px;
			}
		}
	}

	&-title {
		@include font-size(25);
		color: $black;
		position: relative;

		.hero-listing & {
			color: $white;
		}

		@include break(medium) {
			@include font-size(40);

			.hero-listing & {
				@include font-size(60);
			}
		}
	}

	&-summary {
		@include font-size(22);
		line-height: 140%;
		letter-spacing: 0.01em;
		font-family: $font-header-human;

		.hero-listing & {
			color: $black;
		}

		@include break(medium) {
			@include font-size(26);
		}
	}
}

.hero-listing {
	@include break(medium) {
		padding-bottom: 12rem;
	}
}

.back-button-container {
	background-color: $secondary;
	width: 100%;
	z-index: 1;

	.container {
		min-height: auto !important;
		padding-top: $gap;
		padding-bottom: $gap;
		text-align: right;
	}

	.back-button {
		font-size: 90%;
		margin: 0;
		padding: $gap * 0.75 $gap * 1.25 $gap * 0.75 $gap * 3.5 !important;

		&:before {
			background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.41414 15.9999L17.707 30.2928L16.2928 31.707L0.585712 15.9999L16.2928 0.292818L17.707 1.70703L3.41414 15.9999Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 15L35 15L35 17L2 17L2 15Z' fill='white'/%3E%3C/svg%3E%0A");
			height: 24px;
			top: $gap / 1.5;
			width: 24px;
		}

		&:hover {
			&:before {
				background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.41414 15.9999L17.707 30.2928L16.2928 31.707L0.585712 15.9999L16.2928 0.292818L17.707 1.70703L3.41414 15.9999Z' fill='%231E1E1E'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 15L35 15L35 17L2 17L2 15Z' fill='%231E1E1E'/%3E%3C/svg%3E%0A");
			}
		}
	}
}



.has-img {
	+ .single-post,
	+ .single {
		padding-top: $gap;

		@include break(medium) {
			padding-top: $gap * 3;
		}
	}
}
