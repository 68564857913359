/* ++++++++++++++++++++
+++ LAYOUT: FILTERS +++
++++++++++++++++++++ */

.filter {
	@extend %flex;
    align-items: center;
    justify-content: flex-start;
    max-width: $page-width;
	padding: 2rem 1rem;

    @include break(tablet) {
		padding-top: 1rem;
		padding-bottom: 1rem;
		height: 8rem;
    }


    &-outer {
		.listing-three-col & {
			background-color: $purple;
		}

		.listing-two-col & {
			background-color: $primary;
		}

		@include break(medium) {
			margin-top: -12rem;
			margin-right: 0;

			.listing-three-col & {
				max-width: 66.67%;
			}
		}

		@include break(large) {
			.listing-two-col & {
				max-width: 50%;
			}
		}

	    &.small {
		    .filter {
			    max-width: $half-column;
		    }
	    }
    }

    &-button {
        text-align: center;

    }

	label+select {
		margin: 0;
	}

	.filter-submit {
		@include break(tablet) {
			margin-top: 1rem;
		}

		@include break(large) {
			margin-top: 0;
		}
	}

    &-show-button {
        @extend %transition;
		@include font-size(16);
        background-color: transparent;
        background-image: url('../images/select-arrow.svg');
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 16%;
        cursor: pointer;
        display: block;
		font-family: $font-header;
        margin: 0 auto;
        padding: $gap $gap * 2 $gap 0;
		text-transform: uppercase;

		.listing-three-col &,
		.listing-two-col & {
			background-image: url('../images/select-arrow-white.svg');

			&:hover {
				opacity: 0.7;
			}
		}

        @include break(tablet) {
            display: none;
        }

        span {
            color: $white;
        }
    }
    &-inner {
        display: none;
		max-width: $two-thirds-column;

        @include break(tablet) {
            display: block;
        }
    }

	&-search {
		padding-top: $gap * 1.5;

		@include break(tablet) {
			padding-top: 0;
		}
	}

    &-block {
        flex: 0 100%;
        padding: 0 0 $gap * 1.5;
        text-align: left;

        @include break(small) {
            padding: 0 $gap * 2.5 $gap * 1.5;
        }

        @include break(tablet) {
	        flex: 1 12em;
	        padding: 0 $gap;
        }

        label {
	        color: $white;
	        font-weight: $bold;
        }

        select {
			background-position: 94% center;
			border: 0;
			border-radius: 50px;
			direction: ltr;
			font-size: 0.9rem;
			padding-right: $gap * 3;
			text-transform: uppercase;
        }

		input {
			border: none;
			border-radius: 50px;
			margin-top: 0;
		}
    }

    &-submit {
	    background-color: $red;
		padding: 0;
        width: 100%;

        @include break(small) {
		    margin-top: 0;
        }

        &:hover {
	        background-color: $white;
	        color: $black;
        }
    }
}
