/* ++++++++++++++++++++
+++ LAYOUT: ARTICLE +++
++++++++++++++++++++ */

// Article represents the part of a page that includes posted content including title and summary. Displaying as an inline-block allows the article to center nicely with or without a sidebar present.

.article {
	margin-top: 0;
	padding: $gap * 2 0 0;
	position: relative;
	width: 100%;
	color: $white;

	.single-post & {
		color: $black;
	}

	@include break(small) {
		margin-left: 0;
		padding: $gap * 2 0 0;
	}

	&-header {
		&-summary {
			p {
				font: $light 1.33em/1.5 $font-header;
			}
		}
	}

	&-footer {
		border-top: $line solid $grey80;
		margin-top: $gap * 2;
		padding-top: $gap;
	}

	@include break(medium) {
		@include flexbox(66.66%);
		float: right;
		margin-right: 0;

		.single-post & {
			float: none;
			margin-right: 0;
			margin-left: auto;
		}
	}


	//Inline components

	>*+* {
		margin: $gap * 2 0 0
	}

	.accordion-outer,
	.pullquote {
		width: 100%;
	}

	.accordion-outer {
		background-color: $grey90;
	}

	.pullquote {
		&-image {
			+.pullquote-info {
				blockquote {
					font-size: 1.3em;
				}
			}
		}

		cite {
			font-size: 1em;
		}
	}

	.gallery {
		&-thumb {
			padding: 0;
		}

		.slick-slide {
			* {
				height: 100%;
			}
		}

		.slick-dotted {
			&.slick-slider {
				margin-bottom: 60px;
			}

		}

		.slick-prev {
			@include break(tablet) {
				left: -$gap;
			}
		}

		.slick-next {
			@include break(tablet) {
				right: -$gap;
			}
		}
	}

	.download {
		&-block {
			@include flexbox(100%);

			@include break(small) {
				@include flexbox(50%);
			}
		}
	}

	.cta {
		// background-color: $yellow;
		// color: $black;
		padding: $gap * 3;
		border: solid 3px $pink;

		.page-builder & {
			// border-color: $white;
		}

		&-inner {
			text-align: left;
		}

		// .button {
		// 	background: $black;
		// 	color: $white;

		// 	&:before {
		// 		background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1L33 16L18 31' stroke='white' stroke-width='2'/%3E%3Cpath d='M0 16L33 16' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
		// 	}

		// 	&:hover {
		// 		background: $purple;
		// 		color: white;
		// 	}
		// }

	}

	.media-block {
		background-color: transparent;
		border-radius: 0;
		box-shadow: none;
		min-height: auto;
		color: $black;

		&-element {
			@include flexbox(100%);
			border-radius: 0;

			img {
				position: relative;
			}

			.video-outer {
				display: block;
				padding-bottom: 56.25%;
				/* 16:9 */
				height: 0;
				position: relative;

				iframe {
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}
			}
		}

		&-info {
			display: none;
		}

		&-caption {
			background-color: $grey95;
			display: inline-block;
			font-size: 0.9em !important;
			margin-top: 0 !important;
			overflow-wrap: break-word;
			padding: $gap / 2 $gap;
			word-wrap: break-word;
			margin: 0;
			width: 100%;
		}
	}

	.profile {
		margin: $gap * 2 (-$gap * 1.5);

		@include break(medium) {
			margin: $gap * 2 (-$gap * 2);
		}

		&-element {
			margin: $gap * 1.5 0;

			@include break(large) {
				@include flexbox(33.33%);
			}

			&:nth-child(n+4),
			&:nth-child(n+5) {
				margin: $gap * 1.5 0;
			}
		}
	}
}
