//+++++++++++++++++++++++++++
//+++ HELPERS: TYPOGRAPHY +++
//+++++++++++++++++++++++++++

//=======================================
// Helpers
//=======================================


// This is the highlight colour used with the cursor
@include selection($background: $secondary, $color: $white);

// Form placeholder color
@include placeholder {
	color: currentColor;
	opacity: 0.4;
}


//=======================================
// Defaults
//=======================================

%font-header {
	font: $bold 1rem/1.2 $font-header;
}

%font-body {
	font: $normal 1rem/1.5 $font-body;
}

%font-content {
	font: $normal 1rem/1.5 $font-content;
}

%font-meta {
	font: $normal 1rem/1.5 $font-meta;
}

%font-label {
	font: $bold 0.9rem/1.5 $font-header;
	text-transform: uppercase;
	letter-spacing: 0.1em;
}

//=======================================
// Settings
//=======================================

%h1 {
	@extend %font-header;
	@include font-size(40);
	line-height: 130%;

	@include break(large) {
		@include font-size(70);
	}
}

%h2 {
	@extend %font-header;
	@include font-size(32);
	line-height: 130%;

	@include break(large) {
		@include font-size(38);
	}
}

%h3 {
	@extend %font-header;
	@include font-size(26);
	line-height: 130%;

	@include break(large) {
		@include font-size(30);
	}
}

%h4 {
	@extend %font-header;
	@include font-size(20);
	line-height: 130%;

	@include break(large) {
		@include font-size(22);
	}
}

%h5 {
	@extend %font-header;
	@include font-size(18);
	line-height: 130%;

	@include break(large) {
		@include font-size(20);
	}
}

%h6 {
	@extend %font-header;
	@include font-size(16);
	line-height: 130%;
}

p {
	line-height: 160%;
	letter-spacing: 0.01em;

	&.hv {
		font-family: $font-body-human;
	}

	span.hv {
		font-family: $font-body-human;
	}
}
