/* ++++++++++++++++++++++
+++ LAYOUT: ACCORDION +++
++++++++++++++++++++++ */

.section-accordion {
	@extend %flex;
	background-color: $white !important;
	align-items: flex-start;
	// padding: $gap * 2 0;
	padding-bottom: $gap * 3;
	// width: calc(100% + 4rem);
	// margin-left: -$gap * 2;
	// margin-right: -$gap * 2;
	// margin-top: $gap;
	overflow: visible;
	position: relative;

	@include break(medium) {
		margin-top: 0;
		padding-bottom: $gap * 5;
	}

	&:after {
		content: '';
		height: $gap * 4;
		width: 50%;
		background: $white !important;
		position: absolute;
		bottom: -$gap * 4;
		right: 0;
		z-index: 2;

		@include break(medium) {
			bottom: -$gap * 8;
			height: $gap * 8;
		}
	}

	.section-header {
		margin: 0 auto $gap * 2;
	}
}

.accordion {
	&-outer {
		background-color: $white;
		border: 1px solid $primary;
		border-top: $line solid $primary;
		border-bottom: $line solid $primary;
		border-left: 5px solid $primary;
		border-right: 5px solid $primary;
		margin-left: 0;
		max-width: $two-thirds-column;
		overflow: visible;
		position: relative;
	}

	&-item {
		& + & {
			border-top: $line solid $primary;
		}
	}

	&-trigger {
		* {
			pointer-events: none;
		}
	}

	&-button {
		background: none;
		border-radius: 0;
		color: $black;
		display: block;
		font-family: $font-body;
		font-weight: $bold;
		margin: 0;
		padding: $gap $gap * 6 $gap $gap;
		position: relative;
		text-align: left;
		transition: background-color 0.2s ease-in-out;
		width: 100%;

		@include font-size(20);

		@include break(medium) {
			@include font-size(22);
			padding: $gap * 1.5 $gap * 7 $gap * 1.5 $gap * 1.5;
		}

		&:before {
			content: '';
			height: 30px;
			width: 30px;
			background-image: url("data:image/svg+xml,%3Csvg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='28' cy='28' r='28' fill='%231E1E1E'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M27.4999 43.0859L41.7928 28.793L43.207 30.2072L27.4999 45.9143L11.7928 30.2072L13.207 28.793L27.4999 43.0859Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M26.5 44.5L26.5 11.5L28.5 11.5L28.5 44.5L26.5 44.5Z' fill='white'/%3E%3C/svg%3E%0A");
			background-size: cover;
			position: absolute;
			right: $gap;
			top: $gap * 0.85;
			transition: ease 0.3s;

			@include break(medium) {
				height: 32.5px;
				right: $gap * 1.5;
				top: $gap * 1.35;
				width: 32.5px;
			}
		}

		.icon {
			display: inline-block;
			display: none;
			height: 1.05em;
			pointer-events: none;
			position: absolute;
			right: $gap * 2;
			top: $gap * 2;
			transition: 0.235s transform ease-in-out;
			transform: rotate(90deg);
			width: 1.05em;
		}

		&:hover,
		&:focus {
			background-color: $primary;
			box-shadow: none;
			color: $white;
			outline: 0;
			text-decoration: none;

			&:before {
				background-image: url("data:image/svg+xml,%3Csvg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='28' cy='28' r='28' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M27.4999 43.0859L41.7928 28.793L43.207 30.2072L27.4999 45.9143L11.7928 30.2072L13.207 28.793L27.4999 43.0859Z' fill='%231E1E1E'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M26.5 44.5L26.5 11.5L28.5 11.5L28.5 44.5L26.5 44.5Z' fill='%231E1E1E'/%3E%3C/svg%3E%0A");
			}
		}

		&:active,
		&.active {
			background-color: $purple;
			color: $white;

			.icon {
				transform: rotate(-90deg);
				transition: 0.235s transform ease-in-out;
			}

			&:before {
				background-image: url("data:image/svg+xml,%3Csvg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='28' cy='28' r='28' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M27.4999 43.0859L41.7928 28.793L43.207 30.2072L27.4999 45.9143L11.7928 30.2072L13.207 28.793L27.4999 43.0859Z' fill='%231E1E1E'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M26.5 44.5L26.5 11.5L28.5 11.5L28.5 44.5L26.5 44.5Z' fill='%231E1E1E'/%3E%3C/svg%3E%0A");
				transform: rotate(180deg);
			}
		}
	}

	&-panel {
		display: none;
		padding-top: $gap;

		p {
			color: $black;
		}
	}

	&-description {
		padding: 0 $gap $gap $gap;

		p {
			font-size: 100%;
		}

		@include break(medium) {
			font-size: 115%;
			margin-left: 0;
			padding: 0 $gap * 1.5 $gap * 1.5 $gap * 1.5;
			width: 66.66%;
		}
	}
}
