/* ++++++++++++++++++++++++
+++ LAYOUT: MEDIA BLOCK +++
++++++++++++++++++++++++ */

/* ++++++++++++++++++++++++
+++ LAYOUT: MEDIA BLOCK +++
++++++++++++++++++++++++ */

.media-block {
	@extend %flex;
	min-height: 420px;

	.home & {
		margin-bottom: 0;
	}

	&-outer {
		background-color: $blue;
		padding: 0;
		overflow: visible;
		position: relative;
		padding-bottom: $gap * 1;

		@include break(medium) {
			background-color: transparent;
			padding-bottom: $gap * 10;
		}

		.page-builder & {
			padding-bottom: $gap;

			@include break(medium) {
				padding-bottom: 0;
			}
		}

		&:before {
			content: '';
			display: none;
			height: 30vw;
			width: 50%;
			position: absolute;
			bottom: 0;
			right: 0;
			background-color: $blue;
			mix-blend-mode: multiply;
			z-index: 3;

			@include break(medium) {
				display: block;
				height: $gap * 5;
			}

			.single-post &,
			.page-builder & {
				display: none;
			}
		}

		&:after {
			content: '';
			height: 30vw;
			width: 100%;
			position: absolute;
			bottom: 0;
			right: 0;
			background-color: $purple;
			background-image: url('../images/grid-dots-white.svg');
			background-size: contain;
			background-position: top center;
			z-index: 2;

			@include break(medium) {
				background-color: transparent;
			}

			.single-post &,
			.page-builder & {
				display: none;
			}
		}

		&.right {
			background-color: $blue;

			.media-block {
				@include break(medium) {
					flex-flow: row-reverse nowrap;
				}
			}
		}

		&.secondary {
			.media-block {
				background-color: $white;

				&-info {
					color: $black;
				}
			}
		}

		&.video {
			.media-block {
				min-height: auto;

				&-element {
					@include flexbox(100%);
					overflow: hidden;

					@include break(medium) {
						@include flexbox(50%);
					}

					.video-outer {
						display: block;
						padding-bottom: 56.25%;
						/* 16:9 */
						height: 0;
						position: relative;

						iframe {
							height: 100%;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;
						}
					}
				}

				&-info {
					@include break(medium) {
						@include flexbox(50%);
					}
				}
			}
		}

		@include break(medium) {
			margin-bottom: -$gap * 8;
			padding-bottom: 0;

			.home & {
				margin-bottom: 0;
			}
		}

		.home & {
			background-color: $blue;
			position: relative;
			overflow: visible;
			padding-bottom: $gap * 10;

			&+.section {
				padding-top: $gap * 3;
			}

			&:before {
				content: '';
				display: block;
				height: 30vw;
				width: 100%;
				position: absolute;
				bottom: 0;
				right: 0;
				background-color: $blue;
				mix-blend-mode: multiply;
				z-index: 3;

				@include break(medium) {
					width: 50%;
					height: $gap * 8;
				}
			}

			&:after {
				content: '';
				height: 30vw;
				width: 100%;
				position: absolute;
				bottom: 0;
				right: 0;
				background-color: $purple;
				background-image: url('../images/grid-dots-white.svg');
				background-size: contain;
				background-position: top center;
				z-index: 2;

				@include break(medium) {
					width: 50%;
					bottom: calc((30vw - 9rem) * -1);
					background-color: transparent;
				}
			}

			@include break(medium) {
				background-color: $purple;
				padding-bottom: 0;
			}
		}
	}

	&-element,
	&-info {
		margin: 0;
	}

	&-info {
		@extend %flex;
		padding: $gap * 1.5 0;
		background-color: $blue;
		position: relative;
		z-index: 3;

		@include break(medium) {
			@include flexbox(50%);
			padding: $gap * 3;
			margin-top: $gap * 8;
			z-index: 1;

			.home & {
				margin-top: 0;
				margin-bottom: $gap * 8;
			}

			.right & {
				height: 100%;
				margin-top: 0;

				@include break(medium) {
					// background-color: transparent;
				}
			}

			&:after {
				background-color: $blue;
				content: '';
				height: 100%;
				width: 3000px;
				right: -3000px;
				position: absolute;
				top: 0;
			}
		}

		@include break(large) {
			padding: $gap * 4 $gap * 3;
		}


		.button {
			font-size: 1.2em;
			margin-top: 2rem;
		}
	}

	&-content {
		p:not(.media-block-quote) {
			@include font-size(22);
			font-family: $font-body-human;

			a {
				@extend %text-hover;
				text-decoration: underline;
			}
		}
	}

	&-element {
		margin-left: -1rem;
		margin-right: -1rem;
		max-width: calc(100% + 2rem);
		flex: 0 calc(100% + 2rem);
		overflow: hidden;
		position: relative;
		min-height: 500px;

		@include break(medium) {
			margin-right: auto;
			margin-left: calc((100vw - 800px) / 2 * -1);
			flex: 0 calc(50% + ((100vw - 800px) / 2));
			max-width: calc(50% + ((100vw - 800px) / 2));
			min-height: 700px;
			margin-bottom: $gap * 8;

			.home & {
				margin-bottom: 0;
				margin-top: $gap * 8;
			}

			.right & {
				margin-top: 8rem;
				margin-left: auto;
				margin-right: calc((100vw - 800px) / 2 * -1);
				margin-bottom: 0;
			}
		}

		@include break(xlarge) {
			margin-left: calc((100vw - 1280px) / 2 * -1);
			flex: 0 calc(50% + ((100vw - 1280px) / 2));
			max-width: calc(50% + ((100vw - 1280px) / 2));

			.right & {
				margin-right: calc((100vw - 1280px) / 2 * -1);
			}
		}

		// @include break(medium) {
		//     @include flexbox(50%);
		// }

		&-play {
			@extend .button;
			@include centerer(true, true);
			background-color: $red;
			display: inline-block;
			width: auto;
			z-index: 2;

			left: 50%;

			.right & {
				left: 50%;
				z-index: 3;
			}

			@include break(medium) {
				left: 62.5%;

				.right & {
					left: 42.5%;
				}
			}

			@media (min-width: 1024px) {
				left: 62.5%;

				.right & {
					left: 42.5%;
				}
			}

			@media (min-width: 1200px) {
				left: 65%;

				.right & {
					left: 37.5%;
				}
			}

			@include break(xlarge) {
				left: 52.5%;

				.right & {
					left: 47.5%;
				}
			}
		}

		img {
			@include object-fit(16/9, 100%);
			border-radius: 0;

			@include break(medium) {
				left: 0;
				position: absolute;
				top: 0;
			}

			.no-objectfit & {
				width: auto;
			}

			.right & {
				z-index: 2;
			}
		}

		.article & {
			margin-left: 0;
			min-height: auto;
			margin-bottom: 0;
		}

	}

	&-quote {
		@include font-size(25);
		font-family: $font-header-human;
		font-weight: $bold;
		line-height: 130%;

		@include break(medium) {
			@include font-size(40);
		}
	}

	&-attribution {
		@include font-size(14);
		line-height: 130%;
		letter-spacing: 0.2em;
		text-transform: uppercase;
		margin-top: $gap * 2.5;
		margin-left: $gap * 2.5;
		position: relative;
		padding: 0 !important;

		&:before {
			content: '';
			position: absolute;
			left: -$gap * 2.5;
			bottom: $gap / 2;
			height: $gap * 2;
			width: $gap * 2;
			border-left: 2px solid $black;
			border-bottom: 2px solid $black;
		}

		a {
			text-decoration: none !important;

			&:hover {
				color: $black !important;
				text-decoration: underline !important;
			}
		}

	}

	&-title {

		&+p {
			margin-top: $gap * 2;
		}

		@include break(tablet) {
			font-size: 2em;
		}

		.dark & {
			color: $white;
		}
	}

	&-content {
		.dark & {
			p {
				color: $white;
			}
		}
	}

	.button-white {
		margin-top: $gap * 3;
		@include font-size(16);
	}
}
