/* +++++++++++++++++++++++++++
+++ LAYOUT: RESPONSIVE NAV +++
+++++++++++++++++++++++++++ */

// Layout and styling of the navigation at mobile and tablet sizes

.rnav {
	background: $black;
	color: $white;
	margin-top: 0;
	padding-top: 0;

	&-outer {
		display: none;
		overflow: hidden;
		background: $black;
	}

	&-buttons {
		padding:calc(112px + #{$gap}) $gap $gap $gap;
		background: $white;
		display: flex;
		flex-direction: row;
		justify-content: center;

		a {
			width: 50%;

			@include break(medium) {
				width: auto;
			}
		}

		@include break(medium) {
			display: block;
		}

		.button.white {
			background: $pink;
			color: $white;
		}
	}

	&-search {
		padding: $gap;
		padding-bottom: $gap * 14;
		position: relative;

		&:after {
			content: '';
			height: 30vw;
			width: 100%;
			position: absolute;
			bottom: 0;
			right: 0;
			background-image: url('/assets/images/grid-dots-white.svg');
			background-size: contain;
			background-position: top center;
			z-index: 1;
		}

		.search {
			width: 100%;
			z-index: 2;

			&-input {
				border-color: transparent;
				background: $white;
				font-size: 1em;
				height: auto;
				padding: $gap / 1.5 $gap * 2.5 $gap / 1.5 $gap;
				border-radius: 0px;
			}

			&-icon {
				height: 24px;
				right: $gap / 1.8;
				width: 24px;
			}

			&-button {
				border-left: none;
				width: 42px;
				height: 42px;
				background: $black;
				margin: 3px 3px 0 0;
				border-radius: 0px;

				&:after,
				&:before {
					display: none;
				}
			}
		}
	}

	&-item {
		border-top: $line solid rgba($white, 0.1);
		position: relative;

		&-link {
			@extend %transition;
			display: block;
			padding: $gap $gap * 3 $gap $gap;
			text-align: left;

			&:hover {
				background: rgba($white, 0.1);
			}
		}
	}

	&-dropdown {
		background: rgba($black, 0.2);
		display: none;
		overflow: hidden;
		transition: height 500ms ease;
	}

	&-secnav {
		background: black;

		&-item {
			border-bottom: $line solid rgba($white, 0.1);
			display: block;

			&:last-child {
				border-bottom: 0;
			}

			.rnav-item-link {
				font-size: 0.9em;
				font-weight: $normal;

				&:hover {
					background: darken($purple, 5);
					border-color: $primary;
				}
			}
		}
	}

	&-arrow {
		@extend %transition;
		background-color: transparent;
		box-shadow: none;
		cursor: pointer;
		padding: $gap;
		position: absolute;
		right: 0;
		top: 0;
		width: auto;

		&:hover {
			background-color: transparent;
			box-shadow: none;
		}

		&.active {
			transform: rotate(-180deg);
		}

		.icon {
			@include transition(transform 0.4s ease);
			display: block;
			width: $gap * 2;
			color: white;
		}
	}

	&-button {
		border-top: $line solid rgba($white, 0.1);
		padding: $gap;


	}

	@include break(medium) {
		#rnav-open:checked+& {
			display: none;
		}
	}
}
