.floating-share {
	text-align: center;

	@include break(medium) {
		padding-left: $gap * 3;
		width: 70px;
	}

	.share-item {
		@include flexbox(50px);
		display: inline-block;
		margin: 0;

		&+.share-item {
			margin-top: 5px;
		}

		a {
			@extend %transition;
			@extend %flex;
			justify-content: center;
			align-items: center;
			height: 50px;
			width: 50px;
			background: $pink;
			color: $white;

			&:hover {
				background: $yellow;
				color: $black;


			}
		}
	}
}
