.single-meta {
	border-left: 6px solid $purple;
	margin-top: $gap * 4;
	padding-left: $gap * 1.25;

	&-author {
		font-family: $font-body-human;
	}

	&-date {
		margin-top: $gap / 2;
	}

	&-tags {
		margin-top: $gap * 2.25;

		li {
			text-transform: uppercase;

			@media (min-width: 414px) {
				display: inline-block;

				&:after {
					content: '|';
					margin-right: $gap / 3;
					padding-left: $gap / 3;
					width: 100%;
					height: 100%;
				}

				&:last-of-type {
					&:after {
						display: none;
					}
				}
			}
		}

		a {
			color: $secondary;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
