/* ++++++++++++++++++++
+++ LAYOUT: GENERAL +++
++++++++++++++++++++ */

// Universal margins
body,
br,
dd,
option,
td,
th,
svg {
	margin-top: 0;
}

html {
	// @include break(medium) {
		width: 100vw;
		overflow-x: hidden;
	// }
}

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-feature-settings: "kern"1;
	font-kerning: normal;
	background: $black;
	color: $grey20;
	font: $normal 1rem/1.5 $font-body;
	position: relative;

	// @include break(medium) {
		width: 100vw;
		overflow-x: hidden;
	// }
}

//=======================================
// Typography
//=======================================

h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
	word-wrap: break-word;
}

h1 {
	@extend %h1;
}

h2 {
	@extend %h2;
}

h3 {
	@extend %h3;
}

h4 {
	@extend %h4;
}

h5 {
	@extend %h5;
}

h6 {
	@extend %h6;
}

figcaption {
	@extend %font-content;
	background-color: $grey95;
	display: inline-block;
	font-size: 0.9em !important;
	margin-top: 0 !important;
	overflow-wrap: break-word;
	padding: $gap / 2 $gap;
	word-wrap: break-word;

	a {
		@extend %text-hover;
	}
}

a {
	color: inherit;
	text-decoration: none;
}

strong {
	font-weight: $bold;
}

em {
	font-style: italic;
}

hr {
	border: none;
	border-top: $line solid $grey80;
}

img {
	display: block;
	height: auto;
	max-width: 100%;
	text-align: center;
}

sup {
	vertical-align: super;
	font-size: small;
}

sub {
	vertical-align: sub;
	font-size: small;
}

li {
	list-style: none;
}

//=======================================
// Global placeholders and classes
//=======================================

// Full width section
%section {
	margin: 0;
	overflow: hidden;
	padding: $gap * 2 0;
	width: 100%;

	@include break(medium) {
		padding: $gap * 3 0;
	}
}

// Basic layout for a container (usually within a %section)

.components {
	.container {
		padding: 0 $gap * 1;

		@include break(medium) {
			padding: 0 $gap * 2;
		}
	}
}

%container {
	max-width: $page-width;
	padding: 0 $gap;
	position: relative;
	width: 100%;

	@include break(small) {
		padding: 0 $gap * 2;
	}
}

// Card with a white background and drop shadow
%card {
	background: $white;
	// box-shadow: $shadow;
	// border-radius: $corner;
	overflow: hidden;
}

// Hover styles for links and images
%text-hover {
	@extend %transition;

	&:hover {
		color: $secondary;
	}
}

%image-hover {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	display: block;
	overflow: hidden;

	img {
		@extend %transition;
		transform: scale(1);

		&:hover,
		&:focus {
			transform: scale(1.05);
		}
	}
}

// Add a dark filter
%overlay {
	position: relative;

	&:after {
		background: rgba($black, 0.3);
		content: "";
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

%shaded-top,
%shaded-bottom {
	position: relative;

	&:before {
		content: "";
		height: 100%;
		left: 0;
		opacity: 0.5;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

%shaded-top:before {
	@include linear-gradient(top, to bottom,
		rgba($black, 1) 0%,
		rgba($black, 0.738) 19%,
		rgba($black, 0.541) 34%,
		rgba($black, 0.382) 47%,
		rgba($black, 0.278) 56.5%,
		rgba($black, 0.194) 65%,
		rgba($black, 0.126) 73%,
		rgba($black, 0.075) 80.2%,
		rgba($black, 0.042) 86.1%,
		rgba($black, 0.021) 91%,
		rgba($black, 0.008) 95.2%,
		rgba($black, 0.002) 98.2%,
		rgba($black, 0) 100%);
}

%shaded-bottom:before {
	@include linear-gradient(bottom, to top,
		rgba($black, 1) 0%,
		rgba($black, 0.738) 19%,
		rgba($black, 0.541) 34%,
		rgba($black, 0.382) 47%,
		rgba($black, 0.278) 56.5%,
		rgba($black, 0.194) 65%,
		rgba($black, 0.126) 73%,
		rgba($black, 0.075) 80.2%,
		rgba($black, 0.042) 86.1%,
		rgba($black, 0.021) 91%,
		rgba($black, 0.008) 95.2%,
		rgba($black, 0.002) 98.2%,
		rgba($black, 0) 100%);
}

// Sideways text
%sideways {
	@include transform(rotate(180deg));
	-moz-writing-mode: vertical-rl;
	-ms-writing-mode: tb-rl;
	-ms-writing-mode: vertical-rl;
	-webkit-writing-mode: vertical-rl;
	writing-mode: vertical-rl;
	white-space: nowrap;
}

// Vertical centered container
%vertical {
	@include transform(translateY(-50%));
	margin-top: 0;
	position: absolute;
	top: 50%;
	width: 100%;
}

.text-hover {
	@extend %text-hover;
}

// Hide things
.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	padding: 0;
	position: absolute !important;
	overflow: hidden;
	width: 1px;
}

.external-link {
	&:after {
		@extend %transition;
		background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3333 9.88897V15.2223C14.3333 15.6938 14.146 16.146 13.8126 16.4794C13.4792 16.8128 13.0271 17.0001 12.5556 17.0001H2.77778C2.30628 17.0001 1.8541 16.8128 1.5207 16.4794C1.1873 16.146 1 15.6938 1 15.2223V5.44453C1 4.97303 1.1873 4.52085 1.5207 4.18745C1.8541 3.85405 2.30628 3.66675 2.77778 3.66675H8.11111' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.6667 1H17.0001V6.33333' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.22217 10.7778L16.9999 1' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		background-size: 100%;
		content: '';
		display: inline-block;
		height: 16px;
		margin-left: $gap / 2;
		width: 16px;
	}
}

.clearfix:after {
	clear: both;
	content: "";
	display: table;
}

.cookie-notice {
	background: $grey20;
	bottom: 0;
	color: $white;
	font-size: 0.9rem;
	line-height: 1.5;
	padding: $gap / 2;
	position: fixed;
	text-align: center;
	width: 100%;
	z-index: map-get($zindex, popup);
}

.skip-link {
	border-radius: 0;
	left: 50%;
	position: fixed;
	top: -$gap * 10;
	transform: translateY(-100%);
	transition: transform 0.3s;
	z-index: 999;

	&:focus {
		transform: translateY(0%);
	}
}
