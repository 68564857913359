/* +++++++++++++++++++++
+++ LAYOUT: METADATA +++
+++++++++++++++++++++ */

// Metadata on an article page
.metadata {
    @extend %font-meta;
    color: $secondary;

    &-link {
        @extend %text-hover;
        @extend %transition;
        font-weight: $bold;
    }

    time {
        font-weight: $bold;
    }
}

.metalabel {
    @extend %font-label;
    color: $primary;
    display: block;

    a {
        @extend %text-hover;
    }
}

// Tags

// .tags {
// 	margin-top: $gap;
// }

.tags {
    padding: 10px 0 10px 20px;
    border-left: 3px solid $purple;

	.blue & {
		border: 0;
		padding-left: 0;
	}

    & + .card-header{
        margin-top:$gap * 2;
    }

    .facts &{
        border-left:3px solid $yellow;
    }

}
.metatag {
    &-link {
        color: $purple;
        display: inline-block;
        text-transform:uppercase;
        font-family:$font-body;
        font-weight:$bold;
        border-radius:0;

        &:hover {
            color: $pink;
        }

        .facts &{
            color:$yellow;
            @include font-size(30);

            &:hover {
                color: $pink;
            }
        }
    }
}
