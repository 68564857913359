/* +++++++++++++++++
+++ LAYOUT: MAIN +++
+++++++++++++++++ */

// Main is the high level container of content, in between the site header and footer

.main {
	background: $body-color;
	display: block;
	margin: 0;
	// z-index: 2;
	position: relative;

	.page-builder & {
		background: $black;
	}

	&-section {
		@extend %flex;
		@extend %section;
		align-items: flex-start;
		margin: auto;
		max-width: $page-width;
		overflow: visible;
		padding-top: 0;

		.page-builder & {
			background: $black;
			position: relative;

			@include break(medium) {
				&:after {
					content: '';
					position: absolute;
					height: $gap * 8;
					width: 50%;
					right: 0;
					bottom: -$gap * 8;
					background: $black;
				}
			}
		}

		@include break(medium) {
			&:after {
				content: '';
				position: absolute;
				height: $gap * 8;
				width: 50%;
				right: 0;
				bottom: -$gap * 8;
				background: $white;
			}
		}

		.single-post & {
			padding-top: $gap * 3;
		}
	}

	&-header,
	&-footer {
		text-align: center;
	}

	&-header {
		&-title {
			padding-bottom: $gap;
		}

		&-summary {
			p {
				font-size: 1.2em;
			}
		}
	}

	&-section {
		@include break(medium) {
			flex-flow: row wrap;
		}

		>* {
			@extend %flex;
			align-items: flex-start;
			flex-direction: column;
			justify-content: space-between;

			@include break(medium) {
				flex: 1 66.66%;
				flex-direction: row;
			}

			.single-post & {
				justify-content: center;
			}
		}

		&.single {
			>* {
				flex-direction: column-reverse;

				@include break(medium) {
					flex-direction: row;
				}

				.sidebar {
					margin-top: $gap * 2;

					@include break(medium) {
						margin-top: 2rem;
					}
				}
			}
		}
	}

}
