/* ++++++++++++++++++++
+++ LAYOUT: SIDEBAR +++
++++++++++++++++++++ */

// Represents content that is tangentially related to the content nearby, such as tags and subnavigation. within the {% block page %} the sidebar is optional and if included will trigger a two column layout

.sidebar {
	@extend %transition;
	background-color: $grey;
	margin-bottom: $gap;
	padding: $gap * 2 $gap * 2 $gap * 2 $gap;
	width: 100%;
	position: sticky;
	margin-top: $gap;

	.no-sidebar & {
		background-color: transparent;
		padding: 0;
	}

	&.blue {
		background-color: $blue;
	}

	&:before {
		content: '';
		height: 100%;
		width: 3000px;
		position: absolute;
		top: 0;
		left: -1500px;
		background: inherit;
		z-index: 0;

		.no-sidebar & {
			display: none;
		}
	}

	@include break(medium) {
		@include flexbox(29%);
		margin: -2em 0 0;
		margin: 0;
		padding: $gap * 3 $gap * 2 $gap * 3 $gap;
		top: 0;

		&:before {
			content: '';
			height: 100%;
			width: 3000px;
			position: absolute;
			top: 0;
			left: -3000px;
			background: inherit;
		}

		.no-sidebar & {
			padding: 0;
			@include flexbox(60px);
			margin-right: 0;
			margin-right: auto;
			margin-left: -60px;
			padding-left: 60px;
			top: $gap;
		}

	}

	@include break(large) {
		@include flexbox(27%);
	}

	&-show-button {
		@extend %transition;
		background-image: url('../images/select-arrow.svg');
		background-position: center center;
		background-repeat: no-repeat;
		border: 0;
		display: block;
		height: 30px;
		position: absolute;
		right: 0;
		top: 0;
		width: 30px;
		background-color: transparent;

		&:hover {
			transform: rotate(90deg);
		}

		&.active {
			transform: rotate(180deg);
		}

		@include break(medium) {
			display: none;
		}
	}

	&-inner {
		display: none;

		@include break(medium) {
			display: block;
		}
	}

	&-block+&-block {
		margin-top: $gap * 3;
	}

	&-title {
		display: block;
		margin: 0;
		text-transform: uppercase;
		@include font-size(22);
		line-height: 150%;
		letter-spacing: 0.1em;

		@include break(medium) {
			margin: 0 0 $gap;
			padding-bottom: $gap / 2;
		}
	}

	&-block {
		position: relative;
	}

	&-nav {
		margin-top: $gap;
		padding-top: $gap;

		@include break(medium) {
			border-top: 0;
			margin: 0;
			padding-top: 0;
		}

		&-item {
			padding: 0 0 $gap * 0.8;
			position: relative;

			.rnav-arrow {
				color: $black;
				display: inline-block;
				float: right;
				margin-top: -$gap * 1.5;
				padding: 0;
				position: relative;

				.icon {
					color: $black;
				}
			}

			&.current {
				.sidebar-nav-list {
					height: auto;
				}
			}

			.subnav_element {
				@extend %text-hover;
				display: block;

				&:hover,
				&.current {
					color: $black;
					text-decoration: none;
				}

				&.current {
					font-weight: $bold;
				}

				&:hover {
					color: $secondary;
				}
			}

			&.current-menu-item {
				.subnav_element {
					color: $black;
					font-weight: $bold;
				}

				.sidebar-nav-item {
					.subnav_element {
						color: inherit;
						font-weight: $normal;
					}
				}
			}
		}

		&-list {
			.sub-list {
				border-left: solid 5px $black;
				height: 0;
				margin-top: $gap / 1.25;
				padding-left: $gap * 1.5;
				overflow: hidden;
				transition: height 500ms ease;

				.sidebar-nav-item-link {

					&.current {
						margin-left: 0;
					}
				}

				& & {
					margin-left: $gap;
				}

				.rnav-arrow {
					margin-top: -$gap * 2;
				}

				.sidebar-nav-item {

					&:first-of-type {
						margin-top: $gap / 2;
					}
				}

				.sidebar-nav-item-link {
					display: block;
					font-size: 0.9em;
					font-weight: $normal;

					&.current {
						box-shadow: inset 5px 0 currentColor;
						color: $black;
						font-weight: $bold;
						// margin-left: -$gap;
						padding-left: $gap;
					}

					&:hover {
						color: $secondary;
					}
				}

				.sidebar-nav-item {
					// padding-left: $gap;

					&.current-menu-item {
						.sidebar-nav-item-link {
							box-shadow: inset 2px 0 currentColor;
						}

						.sidebar-nav-item {
							.sidebar-nav-item-link {
								box-shadow: none;
							}
						}
					}
				}
			}
		}
	}

	&-meta {
		@extend %flex;
		align-items: flex-start;
		justify-content: space-between;
		margin: 0;
		position: relative;

		&-block {
			margin: $gap / 1.5 0;

			&.info {
				@include flexbox(100%);

				@include break(small) {
					@include flexbox(48%);
				}

				@include break(medium) {
					@include flexbox(100%);
				}
			}

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&.tags {
				margin: 0 (-$gap / 4);
			}

			&.image {
				@include flexbox(100%);

				@include break(small) {
					@include flexbox(48%);
				}

				@include break(medium) {
					@include flexbox(100%);
				}

				img {
					@include object-fit(4/5, 100%);
				}
			}

			.smart-date {

				&--time,
				&--date {
					font-weight: 500;
				}

				&--time:after,
				&--date:before {
					content: ' ';
					display: block;
				}
			}

			span {
				font-weight: $bold;
			}

			&-title {
				@extend %font-content;
				font-weight: $bold;
				margin: 0 $gap / 4 $gap / 2;
				width: 100%;
			}

			&-tag {
				@extend %transition;
				background-color: $primary;
				border-radius: 25px;
				color: $white;
				display: inline-block;
				font: $bold 0.8em/1.5 $font-body;
				margin: $gap / 4 $gap / 4 $gap / 4 0;
				padding: $gap / 4 $gap / 1.5;

				&:hover {
					background: $secondary;
					color: $white;
				}
			}

			&.share {
				justify-content: flex-start;
			}

			&.sidebar-button {
				margin-top: $gap * 1.5;
			}

			&.downloads {
				background-color: $white;
				color: $black;
				margin-top: $gap * 1.5;
				padding: $gap;
				width: 100%;

				.icon {
					display: inline-block;
					margin: 0 $gap / 3 0 0;
					width: 20px;
				}

				h3 {
					color: $primary;
					font-size: 1.2em;
				}

				.downloads-link {
					@extend %transition;
					display: block;
					font-size: 0.9em;
					padding: $gap / 2 0 0 $gap * 2;
					position: relative;

					&:hover {
						color: $primary;
					}

					.icon {
						left: 0;
						position: absolute;
						top: 4px;
					}
				}
			}
		}

		&-share {
			@extend %flex;
			margin: $gap / 2 0 $gap / 1.5;

			&-title {
				@extend %font-content;
				font-weight: $bold;
				margin: 0 0 $gap / 2;
				width: 100%;
			}

			&-button {
				@extend %flex;
				@extend %transition;
				align-items: center;
				background-color: $primary;
				border-radius: 100%;
				height: 30px;
				justify-content: center;
				margin: 0 $gap / 2 0 0;
				position: relative;
				width: 30px;

				&:hover {
					background-color: $secondary;
				}

				&.twitter,
				&.instagram {
					.icon {
						height: 1.4em;
						width: 1.4em;
					}
				}

				&.whatsapp,
				&.link,
				&.mail {
					.icon {
						height: 1em;
						width: 1em;
					}
				}

				.icon {
					color: $white;
				}
			}
		}
	}
}
