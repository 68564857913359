.join-us {
	position: relative;
	padding: $gap * 8 0 $gap * 25 0;

	@include break(medium) {
		padding: $gap * 12 0 $gap * 25 0;
	}

	&-image {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
			object-position: bottom center;
		}
	}

	.container {
		@extend %flex;
	}

	.dots {
		width: 200px;
		height: 200px;
		position: absolute;
		left: calc(50% - 100px);
		bottom: $gap * 7;

		@include break(medium) {
			left: calc(25% - 100px);
			top: 35%;
			bottom: auto;
		}
	}

	&-human,
	&-robot {
		flex: 0 100%;
		max-width: 100%;

		&-inner {
			width: 95%;

			@include break(tablet) {
				width: 70%;
			}

			@include break(medium) {
				width: 50%;
			}
		}
	}

	&-robot {
		&-inner {
			background: $white;
			margin-left: 0;
			padding: $gap;
			position: relative;

			&:before {
				content: '';
				height: 100%;
				width: 3000px;
				left: -3000px;
				position: absolute;
				top: 0;
				background: inherit;
			}

			h2 {
				@include font-size(50);
				font-weight: normal;

				@include break(medium) {
					@include font-size(70);
					text-align: center;
				}
			}
		}
	}

	&-human {
		&-inner {
			background: $black;
			margin-right: 0;
			padding: $gap * 2;
			position: relative;

			&:before {
				content: '';
				height: 100%;
				width: 3000px;
				right: -3000px;
				position: absolute;
				top: 0;
				background: inherit;
			}

			h2,
			p {
				color: white;
			}

			h2 {
				font-family: $font-header-human;
				@include font-size(50);

				@include break(medium) {
					@include font-size(70);
				}
			}

			p {
				font-family: $font-body-human;
				@include font-size(26);
				line-height: 150%;

				&+.button {
					margin-top: $gap * 2;
				}
			}
		}
	}

	.home & {
		&:before {
			content: '';
			height: $gap * 4;
			width: 50%;
			position: absolute;
			right: 0;
			top: 0;
			background: $white;
			z-index: 1;

			@include break(medium) {
				height: $gap * 8;
			}
		}
	}

	&+.footer {
		.footer-top {
			padding-top: $gap * 2;
		}
	}
}
