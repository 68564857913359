.facts {
	.home & {
		overflow: visible;
		position: relative;

		&:after {
			content: '';
			height: $gap * 4;
			width: 50%;
			position: absolute;
			bottom: -$gap * 4;
			left: 0;
			background: $black;
			z-index: 1;

			@include break(medium) {
				height: $gap * 8;
				bottom: -$gap * 8;
			}
		}
	}
}
