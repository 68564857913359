/* ++++++++++++++++++++
+++ LAYOUT: CONTENT +++
++++++++++++++++++++ */

// Content is the section of the page that contains body copy, typically from a WYSIWYG area. This gets all the post styling such as blockquotes and lists.

.content {
	@extend %font-content;

	* {
		margin-top: $gap;

		&:first-child {
			margin-top: 0;
		}
	}

	// Lists
	ol,
	ul {
		margin-top: $gap;
		padding-left: $gap * 1.2;
	}

	ol {
		margin-left: $gap;
	}

	li+li {
		margin: $gap / 2 auto 0;
	}

	ol li {
		list-style: decimal;

		ol li {
			list-style-type: lower-roman;
		}

		ul li {
			list-style-type: circle;
		}
	}

	ul li {
		list-style: disc;

		ul li {
			list-style-type: circle;
		}

		ol li {
			list-style-type: decimal;
		}
	}

	form {

		ol,
		ul {
			padding: 0;
		}

		li {
			list-style: none !important;
		}
	}

	p {
		&:empty {
			display: none;
		}
	}

	p,
	li {
		@include font-size(22);
		font-family: $font-body-human;
		line-height: 160%;
		letter-spacing: 0.01em;
	}

	li li {
		@include font-size(22);
		font-family: $font-body-human;
		line-height: 160%;
		letter-spacing: 0.01em;
	}

	a {
		@extend %text-hover;
		text-decoration: underline;
	}

	// Media
	img {
		// border-radius: $corner;
		margin-top: $gap * 1.5;
		width: 100%;
	}

	.fluid-width-video-wrapper {
		// border-radius: $corner;
		overflow: hidden;
	}

	a img {
		@extend %image-hover;
	}

	// Button
	.button {
		font-size: 1rem;
		margin-top: $gap;
		color: $black;
		background: $white;
		text-decoration: none;

		a {
			text-decoration: none;
			font-family: $font-body;
		}

		&:before {
			background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1L33 16L18 31' stroke='black' stroke-width='2'/%3E%3Cpath d='M0 16L33 16' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
		}

		&:hover {
			background: $yellow;
			color: $black;
		}

		+.btn,
		.button {
			@include break(small) {
				margin-left: $gap;
			}
		}

		.single-post & {
			color: $white;
			background: $black;

			&:before {
				background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1L33 16L18 31' stroke='white' stroke-width='2'/%3E%3Cpath d='M0 16L33 16' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
			}

			&:hover {
				background: $pink;
			}
		}
	}

	.btn {
		display: inline-block;
	}

	// .btn a,
	// .button a,
	// a.btn,
	// a.button {
	// 	border: none;
	// 	box-shadow: none;
	// 	color: $white;
	// 	text-decoration: none;
	// }

	// p.btn,
	// p.button {
	// 	padding: 0;
	// }

	// .btn a,
	// .button a {
	// 	display: block;
	// 	padding: $gap / 2 $gap;
	// }

	// Quotes
	blockquote {
		// background-color: $grey95;
		// border-radius: $corner;
		margin: $gap * 1.5 0;
		position: relative;

		p {
			border-left: solid 6px $secondary;
			display: block;
			font-weight: $bold;
			padding: $gap * 1.5;
			quotes: "“""”""‘""’";

			&:before {
				content: open-quote;
				display: inline-block;
			}

			&:after {
				content: close-quote;
				display: inline-block;
			}
		}
	}

	code {
		// background: $grey95;
		// border-radius: $corner;
		border: 1px solid $grey80;
		font-family: $font-meta;
	}


	sub,
	sup {
		font-size: 75%;
		line-height: 0;
		position: relative;
		vertical-align: baseline;
	}

	sup {
		top: -0.5em;
	}

	sub {
		bottom: -0.25em;
	}

	@include break(medium) {

		.alignleft,
		.float-left,
		.float_left {
			float: left;
			margin: $gap * 1.5 $gap * 1.5 $gap * 1.5 0;
			max-width: 48%;
		}

		.alignright,
		.float-right,
		.float_right {
			float: right;
			margin: $gap * 1.5 0 $gap * 1.5 $gap * 1.5;
			max-width: 48%;
		}
	}
}

@if $wordpress {
	.wp-caption {
		position: relative;
		width: auto !important;

		&-text {
			@extend figcaption;
		}
	}
}

@if $drupal {
	figure[role=group] {
		position: relative;
		width: auto !important;

		figcaption {
			@extend figcaption;
		}
	}
}

figcaption {
	color: $black;
}

pre {
	background-color: $grey95;
	// border-radius: 3px;
	font-size: 85%;
	font-family: $font-meta;
	line-height: 1.45;
	margin: $gap * 3 0;
	overflow: auto;
	padding: $gap;
}

p {
	pre {
		display: inline-block;
		padding: 0;
	}
}

/* client request -  CTSKRSD-62 */
.page-id-14529 .media-block-outer.right {
    background-color: #ff1659!important;
}

.page-id-14529 .media-block-outer.right  .media-block-info {
    background-color: #ff1659!important;
}
