[dir=rtl] {

	.hero-inner-bottom {
		margin-right: 0;
	}
	.home-hero-summary-image {
		direction: ltr;
	}
	.card-wrap-inner, .statistic-item, .media-block-info, .event-inner, .profile-item, .cta-inner {
		text-align: right;
	}
	.section {
		&.wysiwyg, &.profile-outer, &.statistic-outer {
			text-align: right;
		}
	}

	.article {
		padding: $gap * 2 $gap 0;

		@include break(small) {
			padding: $gap * 2 0 0;
		}
	}

    // language selector
    .header {
        .switcher {
            padding-left: 0rem;

            .selected:first-of-type a {
                padding: .6rem 0;
                margin-left: 1rem;
                direction: ltr;

                @include break(small) {
					margin-left: 4rem;
                    padding: .6rem 1rem;
                    text-align: right;
                }
            }

            .option {
                left: 0;
                right: auto;

                @include break(small) {
                    left: auto;
                }
            }
        }

        .header-links {
            margin: 0 auto 0 0;
        }

        .header-logo {
            margin-right: 0;
        }
    }

    .footer-nav-item.social:last-child {
        margin-right: $gap / 1.5;
    }

}
