/* ++++++++++++++++++++
+++ LAYOUT: WYSIWYG +++
++++++++++++++++++++ */

.wysiwyg {
	margin: 0;
	overflow: visible;
	position: relative;

	@include break(medium) {
		padding-top: 9.5rem;
	}

	&.center {
		.content {
			margin: 0 auto;
		}
	}

	.content {
		margin: 0 0 0 auto;

		@include break(medium) {
			width: 66.66%;
		}
	}

	&:after {
		content: '';
		height: $gap * 4;
		width: 50%;
		position: absolute;
		bottom: -$gap * 4;
		right: 0;
		background: inherit;
		z-index: 1;
		display: none;

		@include break(medium) {
			bottom: -$gap * 6;
			height: $gap * 8;
			display: block;
		}
	}

	&+.statistic-outer,
	&+.timeline,
	&+.profile-outer {
		padding-top: $gap * 6;

		@include break(medium) {
			padding-top: $gap * 8;
		}
	}

}
