body.shopwp {
	.wps-search-input-wrapper {
		.css-1vprf2h-SearchIconCSS {
			top: 0.8rem !important;
		}
	}
	.article {
		@include break(medium) {
			@include flexbox(100%);
		}

		.content {
			h2 {
				padding: 1rem 2rem;

				@include break(medium) {
					padding: 2rem 0rem;
				}
				@include break(large) {
					padding: 2rem;
				}
			}
		}

		p.css-6t8i6w-noticeStyles-Notice {
			color: black;
			background-color: white;
		}

		.wps-items.wps-items-list {
			@include break(medium) {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
			}
			@include break(large) {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
			}
			//@include break(xlarge) {
			//	display: grid;
			//	grid-template-columns: repeat(7, 1fr);
			//}

			.wps-item {
				.wps-component {
					&.wps-component-products-title {
						.wps-products-link, .wps-link-modal {
							.wps-products-title {
								color: white !important;
								font-size: 1.125rem;
								text-align: center;
								padding: 0 !important;
								@include break(tablet) {
									min-height: 3.3rem;
								}
								@include break(xlarge) {
									font-size: 1.25rem;
								}
							}
						}
					}
				}
				.wps-component-products-pricing {
					margin-bottom: 10px;
					.wps-pricing-range-wrapper {
						.wps-product-individual-price {
							color: white;
						}
					}
				}
				.wps-component-products-buy-button {
					.wps-component {
						&.wps-component-products-add-button {
							.wps-btn {
								background-color: $white !important;
								color: $black;
								border-radius: 100vmax;
								min-width: fit-content;
								padding: 1rem;
								&:hover {
									background-color: $yellow !important;
								}
							}
						}
					}
				}
			}
		}
		.wps-pagination-controls {
			.wps-btn-next-page {
				background-color: $white !important;
				color: $black;
				border-radius: 100vmax;
				&:hover {
					background-color: $yellow !important;
				}
			}
		}
	}
	.ReactModalPortal {
		.ReactModal__Content {
			.wps-modal {
				&-inner {
					.wps-items.wps-items-list {
						@include break(medium) {
							display: grid;
							grid-template-columns: repeat(1, 1fr);
						}
						@include break(large) {
							display: grid;
							grid-template-columns: repeat(1, 1fr);
						}
						.wps-modal-row {
							.wps-component {
								&.wps-component-products-title {
									.wps-link-modal {
										.wps-products-title {
											color: black !important;
											font-size: 1.125rem;
											text-align: left;
											@include break(tablet) {
												min-height: 3.3rem;
											}
											@include break(xlarge) {
												font-size: 1.25rem;
											}
										}
									}
								}
							}
							.wps-component-products-buy-button {
								.wps-component {
									&.wps-component-products-add-button {
										margin-left: 0;
									}
								}
							}
						}
					}
					.wps-pagination-controls {
						.wps-btn-next-page {
							border-radius: 100vmax;
						}
					}
				}
			}
		}
	}
	.wps-cart {
		&-contents {
			.wps-cart-lineitem-title-content {
				margin-right: 4rem;
			}
		}
	}
	.shop-menu {
		padding-top: 12rem;
		padding-bottom: 2rem;
		text-align: center;
		width: fit-content;
		display: flex;
		justify-content: center;
		margin: 0 auto;
		li {
			position: relative;
			a {
				z-index: 10;
			}
			&:hover {
				.shop-menu__child {
					display: block;
				}
			}
		}
		&__child {
			display: none;
			position: absolute;
			padding-top: 20px;
			z-index: 9;
			width: max-content;
			overflow: hidden;
			li {
				a {
					width: 100%;
					margin: 0;
					border-radius: 0;
				}
			}
		}
	}
	.shop-collections {
		padding: 2rem;
		h2 {
			color: $white;
		}
		&__inner {
			align-items: start;
			padding: 2rem 0;

			@include break(tablet) {
				display: flex;
				justify-content: space-around;
				align-items: flex-end;
				flex-wrap: wrap;
			}
		}
		&__item {
			margin-bottom: 2rem;
			align-self: flex-start;


			//@include break(tablet) {
			//	margin-bottom: 0rem;
			//}
			@include break(medium) {
				width: 30%;
			}
			//@include break(xlarge) {
			//	width: 100%;
			//}


			img {
				min-height: 6.5rem;
				object-fit: cover;
				max-height: 14.5rem;
				margin: 0;
				@include break(medium) {
					min-height: 9.5rem;
					object-fit: cover;
					max-height: 9.5rem;
					margin: 0;
				}
				@include break(xlarge) {
					min-height: 14.5rem;
					object-fit: cover;
					max-height: 14.5rem;
					margin: 0;
				}
			}
			a {
				background-color: white;
				color: black;
				padding: 0.5rem 1rem 0.5rem 0.5rem;
			}
		}
		&__link {
			color: $white;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.shopwp [data-wpshopify-component] {
	max-width: unset !important;
}
.css-17gjhrx-SearchModalCSS {
	z-index: 3;
}
.css-1vprf2h-SearchIconCSS {
	margin-top: 0!important;
	top: 0.8em;
}
body.single-wps_products {
	#headerimg {
		padding: 1rem;
		h1 {
			a {
				color: $white;
			}
		}
		.description {
			color: $white;
		}
	}
	#product_buy_button {
		.wps-product-options {
			margin-left: 0;

			.css-1tm96p5-ClearSelectionsCSS-ClearSelections {
				color: white;
				position: relative !important;
				top: 0px !important;
			}
		}
		.wps-component {
			margin-left: 0;
		}
	}
	.wps-products-title,
	.wps-product-individual-price,
	.wps-component-products-description {
		color: $white;
	}
}
section.view-more {
	text-align: center;
}
.wps-btn-cart {
	background: $pink!important;
	.wps-cart-counter {
		background: none;
	}
}
.hidden-button {
	.wps-pagination-controls {
		display: none;
	}
}
#shopwp-storefront-options,
#shopwp-storefront-page-size {
	display: none;
}
#shopwp-storefront-selections {
	.wps-filter-selections.css-fihllr-StorefrontSelectionsWrapperCSS {
		display: none;
	}
}
#shopwp-storefront-sort {
	.css-1ah7m5e-SelectWrapperCSS {
		align-items: baseline;
	}
	.css-1wy0on6,
	.css-ackcql,
	.css-tlfecz-indicatorContainer {
		margin-top: 0!important;
	}
}
.css-1aju735-SearchCSS {
	.css-17gjhrx-SearchModalCSS {
		position: static;
		max-height: none;
		overflow-y: hidden;
		background: none;
		border: none;
		.wps-product-individual-price {
			color: $white;
		}
	}
}
#react-select-2-listbox {
	color: $black!important;
}
