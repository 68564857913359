/* +++++++++++++++++++++++
+++ LAYOUT: PAGINATION +++
+++++++++++++++++++++++ */

// Pagination links for the listing and features pages

%pagination {
    @extend %flex;
    @extend %font-meta;
	align-items: center;
	background-color: $black;
    text-align: center;
    justify-content: space-between;

    a {
        @extend %transition;

        &:hover {
            background: $yellow;
        }
    }

    &-block {
        margin: 0;
        // width: 48%;
    }

    &-number {
      display: none;
    }

    &-element {
		@extend %transition;
        border-radius: 100%;
		// box-shadow: 0 0 0 $line $white inset;
		color: $white;
        display: block;
        padding: $gap / 4 $gap / 2;
        overflow: hidden;
    }

    &-arrow {
        @extend .button;
        display: block;
    }

    &-current {
        // box-shadow: 0 0 0 $line $secondary inset;
        color: $secondary;
    }

    @include break(tablet) {
        justify-content: center;

        &-number {
            display: block;
        }

        &-block {
            margin: 0 $gap / 2;
            width: auto;
        }

        &-element {
            display: block;
            padding: $gap / 2 $gap;
        }
    }
}

// @if $wordpress {
    .pagination {
        @extend %pagination;

		&-outer {
			background-color: $black;
			position: relative;
			overflow: visible;
			padding-bottom: 2.5rem;
			padding-top: 2.5rem;

			@include break(medium) {
				padding-bottom: 6rem;
				padding-top: 6rem;
			}

			&:after {
				background-color: $black;
				height: 6rem;
				width: 50%;
				content: '';
				position: absolute;
				bottom: -6rem;
				right: 0;
			}
		}

        &_block {
            @extend %pagination-block;

            &.next {
                float: right;
            }

            &.numeral {
                @extend %pagination-number;
				border: solid 2px $white;
				border-radius: 100%;
				font-weight: bold;
				line-height: 1.8;

				a {
					@include font-size(24);
					width: 2.8rem;
					height: 2.8rem;
					padding: 0;

					@include break(medium) {
						@include font-size(30);
						width: 3.6rem;
						height: 3.6rem;
					}
				}

				&:hover {
					background-color: $yellow;
					border-color: $yellow;

					a {
						color: $black;
					}
				}
            }

            &.view_all {
                display: none;
            }
        }

        &_element {
            @extend %pagination-element;

            &.prev {
                @extend %pagination-arrow;
				background-color: $white;
                font-size: 0.8em;
				padding: $gap * 1.85;

				&:before {
					background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 1L2 16L17 31' stroke='%231E1E1E' stroke-width='2'/%3E%3Cpath d='M35 16H2' stroke='%231E1E1E' stroke-width='2'/%3E%3C/svg%3E%0A");
				}

				&:hover {
					&:before {
						background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 1L2 16L17 31' stroke='%231E1E1E' stroke-width='2'/%3E%3Cpath d='M35 16H2' stroke='%231E1E1E' stroke-width='2'/%3E%3C/svg%3E%0A");
					}
				}

                @include break(medium){
                  font-size: 1em;
                }
            }

            &.next {
                @extend %pagination-arrow;
				background-color: $white;
                font-size: 0.8em;
				padding: $gap * 1.85;

				&:before {
					background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1L33 16L18 31' stroke='%231E1E1E' stroke-width='2'/%3E%3Cpath d='M0 16H33' stroke='%231E1E1E' stroke-width='2'/%3E%3C/svg%3E%0A");
				}

				&:hover {
					&:before {
						background-image: url("data:image/svg+xml,%3Csvg width='35' height='32' viewBox='0 0 35 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1L33 16L18 31' stroke='%231E1E1E' stroke-width='2'/%3E%3Cpath d='M0 16H33' stroke='%231E1E1E' stroke-width='2'/%3E%3C/svg%3E%0A");
					}
				}

                @include break(medium){
                  font-size: 1em;
                }
            }

            &.current {
                @extend %pagination-current;
            }
        }
    }
// }

@if $drupal {
    .pager {
	    padding: $gap 0;

	    &__items {
	        @extend %pagination;
	    }

        &-first { float: right; }

        &__item {
	        @extend %pagination-block;

	        a {
	            @extend %pagination-element;
				padding: $gap / 2 $gap;
	        }

	        &.is-active {
	            @extend %pagination-element;
	            @extend %pagination-current;

	            a {
		            @include break(small) {
			            padding: 0;
		            }
	            }
	        }

	        &--first a,
	        &--previous a,
	        &--next a,
	        &--last a {
	            @extend %pagination-arrow;
	            @extend %pagination-block;
	        }
        }

        &-ellipsis {
            @extend %pagination-element;
        }
    }
}
