/* +++++++++++++++++++++++
+++ LAYOUT: STATISTICS +++
+++++++++++++++++++++++ */

.statistic {
	@extend %flex;
	justify-content: space-between;
	text-align: center;
	margin-top: $gap * 4;

	@include break(medium) {
		padding: 0 $gap * 2;
		margin-top: $gap * 5;
	}

	@include break(large) {
		padding: 0;
	}

	&-item {
		@include flexbox(100%);
		margin: 0 0 $gap * 4;
		text-align: left;

		@include break(tablet) {
			@include flexbox(48%);
		}

		@include break(medium) {
			@include flexbox(20%);
			margin: 0;

			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2)~.statistic-item {
				@include flexbox(48%);
			}

			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3)~.statistic-item {
				@include flexbox(30%);
			}
		}

		// &:last-child {
		// 	margin-bottom: $gap;

		// 	@include break(small) {
		// 		margin: 0 0 $gap * 2;
		// 	}
		// }
	}

	&-icon {
		font-size: 2rem;
	}

	&-value {
		display: inline-block;
		text-align: left;
		padding: 0 0 $gap * 5 $gap;
		border-left: 5px solid $black;

		@include break(medium) {
			padding: 0 0 $gap * 10 $gap * 2;
		}

		&-number {
			@include font-size(50);
			font-family: $font-header;
			font-weight: $normal;
			line-height: 0.8;
			color: $black;

			@include break(medium) {
				@include font-size(100);
			}
		}

		&+.statistic-summary {
			margin-top: $gap;

			@include break(medium) {
				margin-top: $gap * 2;
			}
		}
	}

	&-summary {
		@include font-size(20);
		line-height: 30px;
		font-family: $font-body-human;
	}

	&-title {
		font-size: 1.6em;
		padding: 0 0 $gap;
		position: relative;
	}

	.page-builder & {
		&-outer {
			position: relative;
			overflow: visible;

			&+.section:not(.media-block-outer) {
				padding-top: $gap * 4;

				@include break(medium) {
					padding-top: $gap * 8;
				}
			}

			&:after {
				content: '';
				height: $gap * 4;
				width: 50%;
				position: absolute;
				right: 0;
				bottom: -$gap * 4;
				background: inherit;
				z-index: 2;

				@include break(medium) {
					height: $gap * 8;
					bottom: -$gap * 8;
				}
			}
		}
	}

}
